import React, { useState } from "react";
import { Checkbox } from "_common/ui/mui/checkbox/Checkbox";
import { useTranslation } from "react-i18next";
import { INVENTORY_STATUS } from "units/_models/UnitTypeMdl";
import { TFilter } from "admin/_common/filters/TFilter";
import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";
import { ListStore } from "_common/list/ListStore";
import { INVENTORY_FILTERS_KEYS } from "_configs/filtersConfig";

type Props = {
    listStore: ListStore<any>;
};

export function InventoryHideSoldUnits({ listStore }: Props) {
    const { t } = useTranslation();
    const currentPhaseFilter = listStore.filters?.[listStore.getFilterIndex(INVENTORY_FILTERS_KEYS.STATUS)]?.value;
    const [soldAreHidden, setSoldAreHidden] = useState(currentPhaseFilter ?? true);
    const toggleFilter = () => {
        setSoldAreHidden(!soldAreHidden);
        listStore.removeFilterByFilterIdStartBy(INVENTORY_FILTERS_KEYS.STATUS);
        const filters: TFilter[] = [];
        if (!soldAreHidden) {
            filters.push({
                id: INVENTORY_FILTERS_KEYS.STATUS,
                value: INVENTORY_STATUS.AVAILABLE,
                type: TFilterType.ENUM,
            });
        }
        listStore.updateFilters([...listStore.filters, ...filters]);
        listStore.reload();
    };
    return (
        <label className="flex flex-row items-center cursor-pointer">
            <Checkbox
                checked={soldAreHidden}
                onChange={() => {
                    toggleFilter();
                }}
                customColor={"#2CD451"}
                className={"!p-0 !pr-2"}
            />
            <span>{t("property.availableUnits")}</span>
        </label>
    );
}
