import { TFilter } from "admin/_common/filters/TFilter";
import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";

export const DEFAULT_INITIAL_FILTERS: TFilter[] = [{ id: "published", type: TFilterType.BOOLEAN, value: true }];

export enum INVENTORY_FILTERS_KEYS {
    STATUS = "status",
    PHASE = "phaseId",
    VIEWS = "views",
    UNIT_TYPE_IDS = "unitTypeIds",
    DUPLICATE_HIDDEN = "duplicateHidden",
}

export enum FILTER_TYPE {
    propertyType = "propertyType",
    default = "default",
    price = "price",
    bySquareSurface = "bySquareSurface",
    squareSurface = "squareSurface",
}

export enum FILTERS_KEYS {
    TITLE_EN = "localized.en.title",
    TITLE_FR = "localized.fr.title",
    TITLE_ZH = "localized.fr.title",
    TITLE_ES = "localized.fr.title",
    SQUARE_SURFACE_MAX = "squareSurface.max",
    SQUARE_SURFACE_MIN = "squareSurface.min",
    BY_SQUARE_SURFACE_MAX = "bySquareSurface.max",
    BY_SQUARE_SURFACE_MIN = "bySquareSurface.min",
    BATHROOMS_MAX = "bathrooms.max",
    BATHROOMS_MIN = "bathrooms.min",
    BEDROOMS_MAX = "bedrooms.max",
    BEDROOMS_MIN = "bedrooms.min",
    PRICE_MAX = "price.max",
    PRICE_MIN = "price.min",
    TYPE = "type",
    LOCATION = "location",
    PURPOSE = "purpose",
    AMENITIES = "amenities",
    FEATURES = "features",
    DELIVERY_TYPE_START = "phase.startDate",
    DELIVERY_TYPE_END = "phase.endDate",
    STATUS = "phase.status",
    UNIT_TYPE = "unitType",
    PUBLISHED = "published",
    VIEWS = "views",
}
