import { useEffect, useState } from "react";
import { MAIN_RESOURCE } from "_common/resources/BaseResourceStore";
import { TPropertyCard, TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { userStore } from "users/_stores/userStore";
import { toast } from "react-toastify";
import { TUnitCardMdl, TUnitListingMdl } from "admin/units/_models/UnitListingMdl";
import { TUnitTypeListingMdl } from "units/_models/UnitTypeMdl";

type UseFavoriteToggle = {
    isFavorite: boolean;
    toggleFavorite: () => void;
};

export const useFavoriteToggle = (
    type: MAIN_RESOURCE,
    item: TPropertyCard | TPropertyListingMdl | TUnitListingMdl | TUnitTypeListingMdl | TUnitCardMdl,
): UseFavoriteToggle => {
    const [isFavorite, setIsFavorite] = useState(false);
    useEffect(() => {
        try {
            switch (type) {
                case MAIN_RESOURCE.PROPERTIES:
                    setIsFavorite(!!userStore.favoritesProperties.find((property) => property._id === item._id));
                    break;
                case MAIN_RESOURCE.UNIT:
                    setIsFavorite(!!userStore.favoritesUnits.find((unit) => unit._id === item._id));
                    break;
                case MAIN_RESOURCE.UNIT_TYPES:
                    setIsFavorite(!!userStore.favoritesUnitTypes.find((unitType) => unitType._id === item._id));
                    break;
                default:
                    break;
            }
        } catch {
            setIsFavorite(false);
        }
    }, [userStore.favoritesProperties, userStore.favoritesUnits, userStore.favoritesUnitTypes]);

    const toggleFavorite = () => {
        let toggleAction;
        if (!userStore.isLogged) return userStore.openSignInWithModal();
        switch (type) {
            case MAIN_RESOURCE.PROPERTIES:
                toggleAction = userStore.togglePropertyToFavorites(item as TPropertyListingMdl);
                break;
            case MAIN_RESOURCE.UNIT:
                toggleAction = userStore.toggleUnitsToFavorites(item as TUnitListingMdl);
                break;
            case MAIN_RESOURCE.UNIT_TYPES:
                toggleAction = userStore.toggleUnitTypesToFavorites(item as TUnitTypeListingMdl);
                break;
            default:
                toggleAction = userStore.togglePropertyToFavorites(item as TPropertyListingMdl);
        }

        toggleAction
            .then(() => setIsFavorite(!isFavorite))
            .catch((e) => {
                toast.error(e);
            });
    };

    return { isFavorite, toggleFavorite };
};
