import React from "react";
import { SwitchButton } from "_common/ui/components/SwitchButton";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { PROPERTY_SUBTYPE } from "properties/_models/PropertyMdl";
import { useTranslation } from "react-i18next";
import { useSurface } from "_common/surfaces/SurfaceProvider";
import { SURFACE_TYPE } from "_common/surfaces/SurfaceTypes";

export function InventoryToggleSurface() {
    const { surfaceType, setSurfaceType } = useSurface();
    const { t } = useTranslation();
    const propertyStore = usePropertyStore();
    const isBuildableHouse = propertyStore.property.subType === PROPERTY_SUBTYPE.buildableHouse;
    return (
        <>
            <SwitchButton
                className={"w-[70px] m-auto"}
                labelOn={"ft²"}
                labelOff={"m²"}
                value={surfaceType === SURFACE_TYPE.ft}
                onChange={() => {
                    setSurfaceType(surfaceType === SURFACE_TYPE.ft ? SURFACE_TYPE.m : SURFACE_TYPE.ft);
                }}
            />
            {isBuildableHouse && (
                <div className={"text-[10px] sm:text-xs mt-1"}>{t("propertyPage.inventory.lotSize")}</div>
            )}
        </>
    );
}
