import { OPTION_TYPE } from "_configs/propertyConfig";
import { UNIT_OPTIONS } from "_configs/unitConfig";
import { FILTERS_KEYS, INVENTORY_FILTERS_KEYS } from "_configs/filtersConfig";
import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";
import { INVENTORY_STATUS, UNIT_TYPE } from "units/_models/UnitTypeMdl";

export const DEFAULT_UNIT_FILTERS = [
    { id: "type", type: TFilterType.NE, value: UNIT_TYPE.buildableModel },
    { id: "status", type: TFilterType.ENUM, value: INVENTORY_STATUS.AVAILABLE },
];

const unitOptionKeys = UNIT_OPTIONS.map(
    (option) => `${option.optionType === OPTION_TYPE.VIEWS ? "views" : "features"}.${option.key}`,
);

export const UNIT_FILTERS: string[] = [
    FILTERS_KEYS.BATHROOMS_MAX,
    FILTERS_KEYS.BATHROOMS_MIN,
    FILTERS_KEYS.BEDROOMS_MAX,
    FILTERS_KEYS.BEDROOMS_MIN,
    FILTERS_KEYS.PRICE_MAX,
    FILTERS_KEYS.PRICE_MIN,
    FILTERS_KEYS.UNIT_TYPE,
    FILTERS_KEYS.SQUARE_SURFACE_MAX,
    FILTERS_KEYS.SQUARE_SURFACE_MIN,
    FILTERS_KEYS.BY_SQUARE_SURFACE_MAX,
    FILTERS_KEYS.BY_SQUARE_SURFACE_MIN,
    FILTERS_KEYS.STATUS,
    FILTERS_KEYS.DELIVERY_TYPE_START,
    FILTERS_KEYS.DELIVERY_TYPE_END,
    ...Object.values(INVENTORY_FILTERS_KEYS),
    ...unitOptionKeys,
];

export enum UNIT_FILTER_KEY {
    BY_SQUARE_SURFACE = "bySquareSurface",
    DELIVERY_TYPE = "phase.deliveryDate",
    PRICE = "price",
    SQUARE_SURFACE = "squareSurface",
    BEDROOMS = "bedrooms",
    BATHROOMS = "bathrooms",
}
