import { BaseResourceStore } from "_common/resources/BaseResourceStore";
import { fetchUtils } from "_common/_utils/fetchUtils";
import i18next from "i18next";
import { TCommunitiesListingMdl, TCommunityLocalized } from "admin/communities/_models/CommunitiesMdl";
import { action } from "mobx";

export class CommunitiesStore extends BaseResourceStore<TCommunitiesListingMdl> {
    constructor() {
        super("communities");
        this.onInit();
    }

    getByAliasUrl(urlAlias: string, wantedLanguage?: string) {
        if (!wantedLanguage) {
            for (const communityId of Object.keys(this.cache)) {
                if (this.cache[communityId]?.localized.urlAlias === urlAlias) {
                    return this.cache[communityId];
                }
            }
        }

        const url = `${this.apiPath}/urlAlias/${urlAlias}/${wantedLanguage ?? i18next.language}`;
        return fetchUtils
            .get<TCommunitiesListingMdl>(url)
            .then(({ data }) => data)
            .catch((e) => console.error(e));
    }

    getCommunitiesOfGeoZone(geoZoneId?: string, lang: string = i18next.language) {
        const geoZoneParams = geoZoneId ? `/${geoZoneId}` : "";
        const url = `${this.apiPath}/getByGeoZone/${lang}${geoZoneParams}`;
        return fetchUtils.get<{ _id: string; localized: TCommunityLocalized }[]>(url).then(
            action(({ data }) => {
                return data;
            }),
        );
    }
}

const communitiesStore = new CommunitiesStore();
export { communitiesStore };
