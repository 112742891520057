import React from "react";
import { observer } from "mobx-react";
import { PropertyCardListing } from "properties/propertyCard/PropertyCardListing";
import { usePropertiesStore } from "properties/PropertiesPageContext";
import { useSearchPageParams } from "_common/_utils/hookUtils";
import { PROPERTY_PURPOSE } from "properties/_models/PropertyMdl";
import { UNIT_FILTERS } from "_configs/unitFilters";
import { FILTERS_KEYS } from "_configs/filtersConfig";

export const PropertiesViewListItems = observer(() => {
    const propertiesStore = usePropertiesStore();
    const listStore = propertiesStore.getListStore(PROPERTY_PURPOSE.BUY);
    const { isLuxury } = useSearchPageParams();
    const isUnitFilter = listStore.filters.filter((filter) => UNIT_FILTERS.includes(filter.id as FILTERS_KEYS));

    return (
        <>
            {listStore.paginatedItems.map((property) => {
                if (!property) return null;
                return (
                    <PropertyCardListing
                        key={property._id}
                        property={property}
                        onMouseEnter={() => {
                            propertiesStore.setMapPropertySelected(property?._id);
                        }}
                        onMouseLeave={() => {
                            propertiesStore.setMapPropertySelected.cancel();
                            propertiesStore.setMapPropertySelected(undefined);
                        }}
                        isLuxury={isLuxury}
                        isUnitsFiltered={!!isUnitFilter.length}
                    />
                );
            })}
        </>
    );
});
