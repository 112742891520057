import { TFilter } from "admin/_common/filters/TFilter";
import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";
import dayjs from "dayjs";
import { PHASE_STATUS } from "admin/phases/_models/PhasesMdl";
import { FILTERS_KEYS } from "_configs/filtersConfig";

export const TAGS: { key: string; filters: TFilter[] }[] = [
    {
        key: "presale",
        filters: [
            {
                id: FILTERS_KEYS.DELIVERY_TYPE_END,
                type: TFilterType.DATE,
                value: dayjs().startOf("month"),
                op: "gte",
            },
        ],
    },
    {
        key: "pool",
        filters: [
            {
                id: "amenities.pool",
                value: true,
                type: TFilterType.BOOLEAN,
            },
        ],
    },
    {
        key: "luxury",
        filters: [
            {
                id: "features.luxury",
                value: true,
                type: TFilterType.BOOLEAN,
            },
        ],
    },
    {
        key: "beachfront",
        filters: [
            {
                id: "features.beachfront",
                value: true,
                type: TFilterType.BOOLEAN,
            },
        ],
    },
    {
        key: "oceanView",
        filters: [
            {
                id: "views.oceanView",
                value: true,
                type: TFilterType.BOOLEAN,
            },
        ],
    },
    {
        key: "garage",
        filters: [
            {
                id: "features.garage",
                value: true,
                type: TFilterType.BOOLEAN,
            },
        ],
    },
    {
        key: "closingInPesos",
        filters: [
            {
                id: "features.closingInPesos",
                value: true,
                type: TFilterType.BOOLEAN,
            },
        ],
    },
    {
        key: "gatedCommunity",
        filters: [
            {
                id: "features.gatedCommunity",
                value: true,
                type: TFilterType.BOOLEAN,
            },
        ],
    },
    {
        key: "moveInNow",
        filters: [
            {
                id: FILTERS_KEYS.STATUS,
                type: TFilterType.ENUM,
                value: PHASE_STATUS.READY_TO_MOVE_IN,
            },
        ],
    },
    {
        key: "furnishedEquipped",
        filters: [
            {
                id: "features.furnishedEquipped",
                value: true,
                type: TFilterType.BOOLEAN,
            },
        ],
    },
];
