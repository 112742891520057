import React, { ReactNode } from "react";
import { InventoryToggleSurface } from "properties/propertyPage/inventory/InventoryToggleSurface";
import { InventorySurfaceCell } from "./InventorySurfaceCell";
import { InventoryToggleCurrency } from "properties/propertyPage/inventory/InventoryToggleCurrency";
import { InventoryPriceCell } from "properties/propertyPage/inventory/InventoryPriceCell";
import { TUnitListingMdl } from "admin/units/_models/UnitListingMdl";
import { PROPERTY_TYPE } from "properties/_models/PropertyMdl";
import { capitalize } from "_common/_utils/alphaNumUtils";
import { FavoriteBorder, MailOutline } from "@material-ui/icons";
import { InventoryFavorite } from "properties/propertyPage/inventory/InventoryFavorite";
import imageAndI from "../../../assets/images/icons/imageAndI.svg";
import { InventoryViewUnit } from "properties/propertyPage/inventory/InventoryViewUnit";
import { UnitNameHeader } from "properties/propertyPage/inventory/UnitNameHeader";
import { TranslateText } from "_common/_utils/TranslateText";
import { InventoryViewsCell } from "properties/propertyPage/inventory/InventoryViewsCell";
import { UNIT_OPTION } from "_configs/unitConfig";
import { InventoryRequestInfo } from "properties/propertyPage/inventory/InventoryRequestInfo";
import { InventoryRoomsCell } from "properties/propertyPage/inventory/InventoryRoomsCell";
import { InventoryBathCell } from "properties/propertyPage/inventory/InventoryBathCell";
import { FloorNumberCell } from "properties/propertyPage/inventory/FloorNumberCell";

export type TColumnInventory = {
    id: string;
    header: () => ReactNode;
    type?: PROPERTY_TYPE[];
    renderer: (item: TUnitListingMdl) => ReactNode;
};

export const COLUMNS_UNIT_TYPE: TColumnInventory[] = [
    {
        id: "unitNumber",
        header: () => <UnitNameHeader />,
        renderer: (unit) => <div className={"text-sm whitespace-nowrap"}>{unit.name}</div>,
    },
    {
        id: "totalConstructArea",
        header: () => <InventoryToggleSurface />,
        renderer: (unit) => <InventorySurfaceCell unit={unit} />,
    },
    {
        id: "price",
        header: () => <InventoryToggleCurrency />,
        renderer: (unit) => <InventoryPriceCell unit={unit} />,
    },
    {
        id: "status",
        header: () => <TranslateText translateKey={"propertyPage.inventory.statusLabel"} />,
        renderer: (unit) => <div className={"test-xs"}>{capitalize(unit.status.toLowerCase())}</div>,
    },
    {
        id: "save Unit",
        header: () => (
            <div className={"flex flex-col items-center"}>
                <div className={"bg-white rounded-full w-8 h-8 flex justify-center items-center"}>
                    <FavoriteBorder fontSize={"small"} />
                </div>
                <TranslateText translateKey={"propertyPage.inventory.saveUnit"} className={"text-xs"} />
            </div>
        ),
        renderer: (unit) => <InventoryFavorite unit={unit} />,
    },
    {
        id: "info",
        header: () => <MailOutline />,
        renderer: (unit) => <InventoryRequestInfo unit={unit} />,
    },
];

export const COLUMNS: TColumnInventory[] = [
    {
        id: "unitNumber",
        header: () => <UnitNameHeader />,
        renderer: (unit) => (
            <div className={"text-sm whitespace-nowrap"}>
                {unit.variant ? unit.name + " - " + unit.variant : unit.name}
            </div>
        ),
    },
    {
        id: "totalConstructArea",
        header: () => <InventoryToggleSurface />,
        renderer: (unit) => <InventorySurfaceCell unit={unit} />,
    },
    {
        id: "price",
        header: () => <InventoryToggleCurrency />,
        renderer: (unit) => <InventoryPriceCell unit={unit} />,
    },
    {
        id: "bedrooms",
        header: () => <TranslateText translateKey={"propertyPage.inventory.bed"} />,
        renderer: (unit) => <div>{unit.bedrooms}</div>,
        type: [PROPERTY_TYPE.condo, PROPERTY_TYPE.house, PROPERTY_TYPE.villa],
    },
    {
        id: "bathrooms",
        header: () => <TranslateText translateKey={"propertyPage.inventory.bath"} />,
        renderer: (unit) => <InventoryBathCell unit={unit} />,
        type: [PROPERTY_TYPE.condo, PROPERTY_TYPE.house, PROPERTY_TYPE.villa],
    },
    {
        id: "poolNumber",
        header: () => <TranslateText translateKey={"propertyPage.inventory.pools"} />,
        renderer: (unit) => <div>{unit?.features?.[UNIT_OPTION.privatePool] || "-"}</div>,
        type: [PROPERTY_TYPE.condo, PROPERTY_TYPE.house, PROPERTY_TYPE.villa],
    },
    {
        id: "cus",
        header: () => <>CUS</>,
        renderer: (unit) => <div>{unit.cus}</div>,
        type: [PROPERTY_TYPE.land],
    },
    {
        id: "floor",
        header: () => <TranslateText translateKey={"propertyPage.inventory.floorLabel"} />,
        renderer: (unit) => <FloorNumberCell floor={unit.floor} />,
        type: [PROPERTY_TYPE.condo],
    },
    {
        id: "views",
        header: () => <TranslateText translateKey={"propertyPage.inventory.views"} />,
        renderer: (unit) => <InventoryViewsCell unit={unit} />,
        type: [PROPERTY_TYPE.condo],
    },
    {
        id: "status",
        header: () => <TranslateText translateKey={"propertyPage.inventory.statusLabel"} />,
        renderer: (unit) => (
            <div className={"test-xs"}>
                <TranslateText translateKey={`propertyPage.status.${unit.status}`} />
            </div>
        ),
    },
    {
        id: "save Unit",
        header: () => (
            <div className={"flex flex-col items-center"}>
                <div className={"bg-white rounded-full w-8 h-8 flex justify-center items-center"}>
                    <FavoriteBorder fontSize={"small"} />
                </div>
                <TranslateText translateKey={"propertyPage.inventory.saveUnit"} className={"text-xs"} />
            </div>
        ),
        renderer: (unit) => <InventoryFavorite unit={unit} />,
    },
    {
        id: "view unit",
        header: () => <img src={imageAndI} alt={"image infos"} className={"h-[25px] m-auto"} />,
        renderer: (unit) => <InventoryViewUnit unit={unit} />,
    },
    {
        id: "info",
        header: () => <MailOutline />,
        renderer: (unit) => <InventoryRequestInfo unit={unit} />,
    },
];

export const COLUMNS_MOBILE: TColumnInventory[] = [
    {
        id: "unitNumber",
        header: () => <UnitNameHeader />,
        renderer: (unit) => <div className={"text-xs sm:text-sm whitespace-nowrap"}>{unit.name}</div>,
    },
    {
        id: "price",
        header: () => <InventoryToggleCurrency />,
        renderer: (unit) => <InventoryPriceCell unit={unit} />,
    },
    {
        id: "rooms",
        header: () => <TranslateText translateKey={"words.rooms"} className={"w-[calc(25vw-26px)] sm:w-auto"} />,
        renderer: (unit) => <InventoryRoomsCell unit={unit} />,

        type: [PROPERTY_TYPE.condo, PROPERTY_TYPE.house, PROPERTY_TYPE.villa],
    },
    {
        id: "lotSurface",
        header: () => <InventoryToggleSurface />,
        renderer: (unit) => <InventorySurfaceCell unit={unit} />,
    },
    {
        id: "poolNumber",
        header: () => <TranslateText translateKey={"propertyPage.inventory.pools"} />,
        renderer: (unit) => <div>{unit?.features?.[UNIT_OPTION.privatePool] || "-"}</div>,
        type: [PROPERTY_TYPE.condo, PROPERTY_TYPE.house, PROPERTY_TYPE.villa],
    },
    {
        id: "floor",
        header: () => <TranslateText translateKey={"propertyPage.inventory.floorLabel"} />,
        renderer: (unit) => <FloorNumberCell floor={unit.floor} />,
        type: [PROPERTY_TYPE.condo],
    },
    {
        id: "views",
        header: () => <TranslateText translateKey={"propertyPage.inventory.views"} />,
        renderer: (unit) => <InventoryViewsCell unit={unit} />,
        type: [PROPERTY_TYPE.condo],
    },
    {
        id: "status",
        header: () => <TranslateText translateKey={"propertyPage.inventory.statusLabel"} />,
        renderer: (unit) => <div className={"test-xs"}>{capitalize(unit.status.toLowerCase())}</div>,
    },
    {
        id: "viewLot",
        header: () => (
            <div className={"h-[25px] w-[calc(25vw-26px)] sm:w-auto flex justify-center"}>
                <img src={imageAndI} alt={"image and informations"} className={"h-full w-full"} />
            </div>
        ),
        renderer: (unit) => <InventoryViewUnit unit={unit} />,
    },
    {
        id: "save Unit",
        header: () => (
            <div className={"flex flex-col items-center"}>
                <div className={"bg-white rounded-full w-8 h-8 flex justify-center items-center"}>
                    <FavoriteBorder fontSize={"small"} />
                </div>
                <TranslateText
                    translateKey={"propertyPage.inventory.saveUnit"}
                    className={"text-[10px] sm:text-xs whitespace-nowrap"}
                />
            </div>
        ),
        renderer: (unit) => <InventoryFavorite unit={unit} />,
    },
    {
        id: "info",
        header: () => <MailOutline />,
        renderer: (unit) => <InventoryRequestInfo unit={unit} />,
    },
];
