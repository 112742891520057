import React from "react";
import checkCircle from "../../assets/images/icons/checkCircle.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { PhaseLabel } from "properties/propertyPage/inventory/PhaseLabel";

export function PropertyPhases() {
    const { property } = usePropertyStore();
    if (!property.inventory?.phases?.length) return null;
    return (
        <div className="border rounded-lg pl-[23px] py-5 space-y-1.5 border-[var(--color-separator)]">
            {property.inventory.phases.map((phase, index) => {
                return (
                    <div key={index} className="flex items-center text-sm">
                        <div className="flex-shrink-0">
                            <LazyLoadImage src={checkCircle} alt="check icon" />
                        </div>
                        <div className="ml-3">
                            <div>
                                <PhaseLabel phase={phase} />
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}
