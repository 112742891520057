import React, { lazy, useEffect, useState } from "react";
import { SwitchButtonPropertyDetails } from "properties/propertyPage/SwitchButtonPropertyDetails";
import { PropertyPriceAndAddress } from "properties/propertyPage/PropertyPriceAndAddress";
import { PropertyDetails } from "properties/propertyPage/PropertyDetails";
import { PropertyCollapsibleSections } from "properties/propertyPage/PropertyCollapsibleSections";
import { PropertyMapRender } from "properties/propertyPage/PropertyMapRender";
import Suspenser from "_common/loaders/Suspenser";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { PropertyUnitTypeListLoader } from "properties/propertyPage/PropertyUnitTypeListLoader";
import { PROPERTY_TYPE } from "properties/_models/PropertyMdl";
import { PropertyLuxuryLandDetails } from "properties/propertyPage/PropertyLuxuryLandDetails";
import { isLuxury } from "_common/_utils/propertyUtils";
import { PropertyDetailsNoInventory } from "properties/propertyPage/PropertyDetailsNoInventory";
import PropertyPriceCards from "properties/propertyPage/PropertyPriceCards";
import { MAIN_RESOURCE } from "_common/resources/BaseResourceStore";
import { cn } from "_configs/twconfig";

export enum PROPERTY_TABS {
    INVENTORY = "INVENTORY",
    BUILDABLE_HOUSE = "BUILDABLE_HOUSE",
    DETAILS = "DETAILS",
}

const LazyPropertySimilarProperties = lazy(() => import("properties/propertyPage/PropertySimilarProperties"));
const LazyPropertyInventory = lazy(() => import("properties/propertyPage/inventory/PropertyInventory"));
const LazyContactForm = lazy(() => import("properties/propertyPage/PropertyOrUnitContactForm"));

export function PropertyContent() {
    const { property } = usePropertyStore();
    const isInventory = !!property?.inventory?.phases?.length && property.inventory.phases.length > 0;
    const [propertyTabs, setPropertyTabs] = useState(PROPERTY_TABS.DETAILS);
    const hasNoInventory = !property?.nbOfUnits || property.nbOfUnits < 1;
    const priceNotDisplayed = property.priceIsNotDisplayed;

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const unitName = urlParams.get("unitName");
        if (unitName) {
            setPropertyTabs(PROPERTY_TABS.INVENTORY);
        }
    }, []);

    return (
        <>
            {isInventory && (
                <SwitchButtonPropertyDetails value={propertyTabs} onChange={(value) => setPropertyTabs(value)} />
            )}
            {propertyTabs === PROPERTY_TABS.INVENTORY && (
                <Suspenser noSpinner noText>
                    <LazyPropertyInventory />
                </Suspenser>
            )}
            {propertyTabs === PROPERTY_TABS.BUILDABLE_HOUSE && <PropertyUnitTypeListLoader />}
            <div className="flex flex-col mt-6 md:flex-row md:gap-12 md:mt-10 pb-3">
                <div className="flex-1 space-y-10 md:w-2/4">
                    <div className={cn("space-y-10", { hidden: propertyTabs !== PROPERTY_TABS.DETAILS })}>
                        {hasNoInventory && !priceNotDisplayed ? <PropertyPriceCards /> : <PropertyPriceAndAddress />}

                        {isLuxury(property) && property.type === PROPERTY_TYPE.land ? (
                            <PropertyLuxuryLandDetails />
                        ) : (
                            <>{hasNoInventory ? <PropertyDetailsNoInventory /> : <PropertyDetails />}</>
                        )}
                        <PropertyCollapsibleSections />
                    </div>
                    <PropertyMapRender />
                    <div className="block lg:hidden relative w-full">
                        <Suspenser>
                            <LazyContactForm options={{ resource: MAIN_RESOURCE.PROPERTIES }} />
                        </Suspenser>
                    </div>
                    <Suspenser noSpinner noText>
                        <LazyPropertySimilarProperties />
                    </Suspenser>
                </div>
                <div className="relative hidden lg:block md:w-1.5/4 mt-2.5">
                    <Suspenser>
                        <LazyContactForm options={{ resource: MAIN_RESOURCE.PROPERTIES }} />
                    </Suspenser>
                </div>
            </div>
        </>
    );
}
