import React, { useMemo } from "react";
import { INVENTORY_STATUS, TUnitTypeListingMdl } from "units/_models/UnitTypeMdl";
import { UnitTypeStore } from "unitTypes/_store/UnitTypeStore";
import { UnitTypeContext } from "unitTypes/_store/UnitTypeContext";
import { GALLERY_TABS } from "_configs/propertyConfig";
import { GalleryListModal } from "units/UnitViewGalleryListModal";
import { useTranslation } from "react-i18next";
import { UnitGallery } from "units/UnitGallery";
import ShareButton from "properties/propertyPage/propertyView/ShareButton";
import { unitsStore } from "units/_stores/unitsStore";
import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";
import { UnitTypeHeader } from "unitTypes/UnitTypeHeader";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { UnitTypeInventory } from "unitTypes/UnitTypeInventory";
import { PhaseEarlyUnitType } from "unitTypes/PhaseEarlyUnitType";
import { INVENTORY_FILTERS_KEYS } from "_configs/filtersConfig";
import { UnitCheckList } from "units/UnitCheckList";
import { UnitFloorPlans } from "units/UnitFloorPlans";
import { UNIT_OPTION } from "_configs/unitConfig";

type Props = {
    unitType: TUnitTypeListingMdl;
};

export function UnitTypePage({ unitType }: Props) {
    const unitTypeStore = useMemo(() => new UnitTypeStore(unitType), [unitType]);
    const { t } = useTranslation();
    const { property } = usePropertyStore();
    const listStore = unitsStore.getListStore(unitType._id, Number.MAX_SAFE_INTEGER, [
        { id: "propertyId", type: TFilterType.ID, value: property._id },
        { id: "unitTypeIds", type: TFilterType.ID, value: unitType._id },
        { id: "duplicateHidden", type: TFilterType.BOOLEAN, value: true },
        {
            id: INVENTORY_FILTERS_KEYS.STATUS,
            value: INVENTORY_STATUS.AVAILABLE,
            type: TFilterType.ENUM,
        },
    ]);

    return (
        <UnitTypeContext unitTypeStore={unitTypeStore}>
            <div className="my-5 mx-5 md:mx-16">
                <div className="hidden sm:flex justify-between mb-5">
                    <div className="flex items-baseline gap-2">
                        <LoadableFromLoading
                            loading={listStore.currentLoadingState}
                            renderer={() => {
                                return <PhaseEarlyUnitType />;
                            }}
                        />
                    </div>
                    <div className="flex mr-8 sm:mr-0 gap-x-2">
                        <ShareButton />
                    </div>
                </div>
                <UnitGallery images={unitType.photos ?? []} />
                <LoadableFromLoading
                    loading={listStore.currentLoadingState}
                    renderer={() => {
                        return <UnitTypeHeader />;
                    }}
                />
                <LoadableFromLoading
                    loading={listStore.currentLoadingState}
                    renderer={() => {
                        return <UnitTypeInventory />;
                    }}
                />

                {unitType.features && (
                    <UnitCheckList
                        title={t("unit.featuresTitle")}
                        datas={Object.entries(unitType.features).filter(
                            ([key]) => unitType.features?.[key as keyof typeof UNIT_OPTION],
                        )}
                    />
                )}
                <UnitFloorPlans floorPlans={unitTypeStore.unitType.floorPlans} />
            </div>
            <GalleryListModal
                galleryImages={[
                    {
                        images: unitType.photos ?? [],
                        tabName: GALLERY_TABS.GALLERY,
                        label: t("property.galleryNavbar.mainGallery"),
                    },
                    {
                        images: unitType.floorPlans ?? [],
                        tabName: GALLERY_TABS.LEVEL_PLAN,
                        label: t("property.galleryNavbar.levelPlan"),
                    },
                ]}
            />
        </UnitTypeContext>
    );
}
