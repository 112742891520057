import React from "react";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import units from "../../assets/images/icons/units.svg";
import propertyType from "../../assets/images/icons/propertyType.svg";
import bed from "../../assets/images/icons/bed.svg";
import size from "../../assets/images/icons/UnitModalHeader/size.svg";

import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { convert, UNIT_CONVERSION } from "_common/_utils/unityUtils";
import { PROPERTY_SUBTYPE, PROPERTY_TYPE } from "properties/_models/PropertyMdl";
import UnitOrPropertyHeaderHoa from "units/UnitHeader/UnitOrPropertyHeaderHoa";

export function PropertyDetails() {
    const { t } = useTranslation();
    const { property } = usePropertyStore();
    const isInventory = !!property?.inventory?.phases?.length && property.inventory.phases.length > 0;
    const isBuildableHouse = property.subType === PROPERTY_SUBTYPE.buildableHouse;
    const formatBedroomNumber = (min: number, max = 0) => {
        const formatNumber = (num: number) => (num === 0 ? t("propertyPage.header.studio") : num);
        return min === max
            ? t("propertyPage.bedUnit", { unit: formatNumber(min) })
            : t("propertyPage.bedUnits", {
                  unit: Array.from({ length: max - min + 1 }, (_, i) => formatNumber(i + min))
                      .join(", ")
                      .replace(/, ([^,]*)$/, " & $1"),
              });
    };

    return (
        <div className={"flex flex-col gap-5 ml-2.5"}>
            {isInventory && (
                <div className={"flex items-center gap-5"}>
                    <LazyLoadImage src={units} />
                    {t(property.type !== PROPERTY_TYPE.land ? "property.unitsAvailable" : "property.lotsAvailable", {
                        count: property.nbOfUnitsAvailable,
                    })}{" "}
                    /{" "}
                    {t(property.type !== PROPERTY_TYPE.land ? "property.totalUnits" : "property.totalLots", {
                        count: property.nbOfUnitsManual ?? property.nbOfUnits,
                    })}
                </div>
            )}

            <div className={"flex items-center gap-6"}>
                <LazyLoadImage src={propertyType} />
                <span>
                    {t("propertyPage.propertyType")}
                    <span className={"capitalize"}>{property.type}</span>
                </span>
            </div>
            {isBuildableHouse && !!property.squareSurface.min && (
                <div className={"flex items-center gap-6 text-sm"}>
                    <LazyLoadImage src={size} />
                    <div className={"flex flex-col"}>
                        {t("propertyPage.header.lotSizeRange")}
                        {t("propertyPage.header.lotSizeRangeMin", {
                            m: property.squareSurface.min,
                            ft: convert(property.squareSurface.min, UNIT_CONVERSION.M2_TO_FT2_BACK),
                        })}
                        {!!property.squareSurface.max &&
                            t("propertyPage.header.lotSizeRangeMax", {
                                m: property.squareSurface.max,
                                ft: convert(property.squareSurface.max, UNIT_CONVERSION.M2_TO_FT2_BACK),
                            })}
                    </div>
                </div>
            )}
            {property.bedrooms.min > -1 && (
                <div className={"flex items-center gap-6"}>
                    <LazyLoadImage src={bed} />
                    {formatBedroomNumber(property.bedrooms.min, property.bedrooms.max)}
                </div>
            )}
            {!!property.fees && !!property.fees.value && (
                <UnitOrPropertyHeaderHoa
                    title={t("propertyPage.fees")}
                    description={t(`propertyPage.feesTypes.${property.fees.type}`, {
                        count: property.fees.value,
                    })}
                />
            )}
        </div>
    );
}
