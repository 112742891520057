import React from "react";
import { useFormContext } from "react-hook-form";
import { useLoadingFromPromise } from "_common/loaders/useLoadingFromPromise";
import { useTranslation } from "react-i18next";
import { ErrorBlock } from "_common/errors/ErrorBlock";
import { notifierStore, TAskForVisitOrInfoForm } from "components/misc/contactUs/_stores/notifierStore";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { observer } from "mobx-react";
import { AC_TAGS } from "users/_models/UserMdl";
import i18next from "i18next";
import { useLocation } from "react-router-dom";
import { ButtonNotification } from "components/misc/notifications/ButtonNotification";
import { TOptionsForm } from "gallery/_store/GalleryStore";
import { MAIN_RESOURCE } from "_common/resources/BaseResourceStore";

type Props = {
    options: TOptionsForm;
};

export const ProjectFormBtn = observer(({ options }: Props) => {
    const { pathname } = useLocation();
    const propertyStore = usePropertyStore();
    const { handleSubmit, errors } = useFormContext<TAskForVisitOrInfoForm>();
    const { loading, setPromise } = useLoadingFromPromise();
    const isLoading = loading?.status === "LOADING";
    const { t } = useTranslation();
    const errorMessage = errors.message ? errors.message.message : errors.email ? errors.email.message : "";
    return (
        <>
            {loading?.isSucceeded && (
                <div id={"successRequest"} className={"text-sm mt-2"}>
                    {t("contactPage.succeededSend")}
                </div>
            )}
            <ErrorBlock error={errorMessage ?? loading?.error} />
            <div className="pt-4 flex items-center">
                <ButtonNotification
                    className={"!rounded-3xl !text-sm !font-normal !py-3.5 !px-[38px]"}
                    variant={"contained"}
                    color={"primary"}
                    onClick={handleSubmit<TAskForVisitOrInfoForm>((data) => {
                        if (!isLoading) {
                            setPromise(
                                notifierStore.askForInfo({
                                    ...(data as TAskForVisitOrInfoForm),
                                    propertyId: propertyStore.property._id,
                                    unitId: options.resource === MAIN_RESOURCE.UNIT ? options?.unitId : "",
                                    acTags:
                                        options.resource === MAIN_RESOURCE.UNIT ? AC_TAGS.L1_UNIT : AC_TAGS.L2_PROPERTY,
                                    lang: i18next.language,
                                    url: pathname,
                                }),
                            );
                        }
                    })}
                >
                    {isLoading ? t("loaders.message") : t("propertyPage.contactForm.submit")}
                </ButtonNotification>
            </div>
        </>
    );
});
