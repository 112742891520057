import React from "react";
import { Meta } from "_common/_utils/Meta";
import i18next from "i18next";
import { useSearchPageParams } from "_common/_utils/hookUtils";
import { usePropertiesStore } from "properties/PropertiesPageContext";
import { reformatStringForUrls } from "_common/_utils/alphaNumUtils";
import sharedConfig, { TLang } from "_configs/sharedConfig";
import { getI18nExpByLang } from "_common/_utils/pageUtils";
import { HIDDEN_PROPERTY_TYPE } from "properties/_models/PropertyMdl";
import { PARAMS_NORTH_PREFIX, PARAMS_PAGE_PREFIX, PARAMS_ZOOM_PREFIX } from "_common/_utils/searchUtils";
import { useLocation } from "react-router-dom";

export function PropertiesMeta() {
    const location = useLocation();

    const {
        isAtLeastProvincePage,
        isAtLeastCityPage,
        isNeighborhoodPage,
        propertyTypeLocalizedUrl,
    } = useSearchPageParams();
    const propertiesStore = usePropertiesStore();

    let propertyKey = "";
    const types = getI18nExpByLang(i18next.language, "property.types");
    for (const key of Object.keys(types)) {
        const tmpLang = i18next.language === "zh" ? "en" : i18next.language;
        if (
            getI18nExpByLang(tmpLang, "property.types." + key)
                .replace(/ /g, "_")
                .toLowerCase() === propertyTypeLocalizedUrl
        ) {
            propertyKey = (key as unknown) as string;
        }
    }
    const alternateUrls = [];
    for (const lang of Object.keys(sharedConfig.languages)) {
        let route = "";
        if (isAtLeastProvincePage && propertiesStore.addressParams) {
            route =
                sharedConfig.appUrl +
                "/" +
                lang +
                "/" +
                getI18nExpByLang(lang, "routes.buy") +
                "/" +
                reformatStringForUrls(
                    !isAtLeastCityPage
                        ? propertiesStore.addressParams.regionLong
                        : propertiesStore.addressParams.region,
                ) +
                "/" +
                (isAtLeastCityPage ? `${reformatStringForUrls(propertiesStore.addressParams.city)}/` : "") +
                (isNeighborhoodPage ? `${reformatStringForUrls(propertiesStore.addressParams.neighbourhood)}/` : "") +
                (propertyKey
                    ? `${getI18nExpByLang(lang === "zh" ? "en" : lang, "property.types." + propertyKey).toLowerCase()}/`
                    : "");
        } else {
            route =
                sharedConfig.appUrl +
                "/" +
                lang +
                "/" +
                getI18nExpByLang(lang, "routes.buy") +
                "/" +
                (propertyTypeLocalizedUrl
                    ? getI18nExpByLang(lang === "zh" ? "en" : lang, "property.types." + propertyKey).toLowerCase()
                    : "");
        }

        alternateUrls.push({
            url: route,
            lang: lang as TLang,
        });
    }

    const url =
        sharedConfig.appUrl +
        "/" +
        location.pathname
            .split("/")
            .filter(
                (x) =>
                    !!x &&
                    !x.startsWith(PARAMS_NORTH_PREFIX) &&
                    !x.startsWith(PARAMS_ZOOM_PREFIX) &&
                    !x.startsWith(PARAMS_PAGE_PREFIX),
            )
            .join("/");

    return (
        <Meta
            title={undefined}
            description={undefined}
            url={url}
            alternateUrls={alternateUrls}
            noIndex={HIDDEN_PROPERTY_TYPE.includes(propertyKey)}
        />
    );
}
