import React from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { Separator } from "_common/ui/components/Separator";
import close from "../assets/images/icons/closeModal.svg";
import ShareButton from "properties/propertyPage/propertyView/ShareButton";
import ViewFavoriteButton from "properties/propertyPage/propertyView/ViewFavoriteButton";
import { galleryStore, TGalleryImage } from "gallery/_store/GalleryStore";
import { MAIN_RESOURCE } from "_common/resources/BaseResourceStore";
import { TUnitListingMdl } from "admin/units/_models/UnitListingMdl";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";

interface Props {
    handleClose: () => void;
    galleryImages: TGalleryImage[];
    item: TUnitListingMdl;
}

export default function UnitViewGalleryNavbar({ handleClose, galleryImages, item }: Props) {
    const { t } = useTranslation();
    const { property } = usePropertyStore();
    return (
        <div className="fixed top-0 left-0 w-full bg-white z-50">
            <div className="absolute right-5 top-3 z-60 cursor-pointer" onClick={handleClose}>
                <img src={close} className="h-4" alt="close-modal" />
            </div>
            <div className="flex flex-col-reverse justify-between sm:items-center mx-4 sm:mx-14 my-4 gap-4 md:flex-row md:gap-0">
                <nav className="flex space-x-5 z-50 overflow-x-auto whitespace-nowrap md:overflow-visible no-scrollbar">
                    {galleryImages.map((galleryImage, idx) => {
                        if (!galleryImage.images.length) return null;
                        return (
                            <p
                                key={idx}
                                className={clsx("cursor-pointer shrink-0", {
                                    underline: galleryImage.tabName === galleryStore.openedGalleryListTab,
                                })}
                                onClick={() => galleryStore.setOpenedGalleryTab(galleryImage.tabName)}
                            >
                                {`${galleryImage.label} (${galleryImage.images.length})`}
                            </p>
                        );
                    })}
                </nav>
                <Separator className={"sm:hidden"} />
                <div className="flex gap-x-5 justify-between items-center">
                    <button
                        className="w-52 text-white bg-[#1A3A45] rounded-full py-2.5"
                        onClick={() =>
                            galleryStore.openContactForm({
                                resource: MAIN_RESOURCE.UNIT,
                                title: t("unitPage.form.title", {
                                    unit: `#${item.name}`,
                                    property: property.localized.title,
                                }),
                                unitId: item._id,
                            })
                        }
                    >
                        {t("property.galleryNavbar.contact")}
                    </button>
                    <div className="flex mr-8 sm:mr-0 gap-x-2">
                        <ShareButton />
                        {item && <ViewFavoriteButton item={item} resource={MAIN_RESOURCE.UNIT} />}
                    </div>
                </div>
            </div>
        </div>
    );
}
