import React from "react";
import { observer } from "mobx-react";
import { useFormContext } from "react-hook-form";
import { useLoadingFromPromise } from "_common/loaders/useLoadingFromPromise";
import { notifierStore } from "components/misc/contactUs/_stores/notifierStore";
import { ErrorBlock } from "_common/errors/ErrorBlock";
import { useTranslation } from "react-i18next";
import { AC_TAGS } from "users/_models/UserMdl";
import { BLOG_TYPE } from "pages/_models/PageMdl";
import i18next from "i18next";
import { useLocation } from "react-router-dom";
import { ButtonNotification } from "components/misc/notifications/ButtonNotification";
import { toast } from "react-toastify";
import { TContactForm } from "contact/ContactUsForm";

type Props = {
    acTags?: AC_TAGS;
    blogType?: BLOG_TYPE;
    blogTitle?: string;
};
export const ContactFormBtn = observer((props: Props) => {
    const { handleSubmit } = useFormContext<TContactForm>();
    const { pathname } = useLocation();
    const { loading, setPromise } = useLoadingFromPromise();
    const isLoading = loading?.status === "LOADING";
    const { t } = useTranslation();
    return (
        <>
            {loading?.isSucceeded && (
                <div id={"successRequest"} className={"text-sm mt-2"}>
                    {t("contactPage.succeededSend")}
                </div>
            )}
            <ErrorBlock error={loading?.error} />
            <div className="pt-4 flex items-center">
                <ButtonNotification
                    className={"!rounded-3xl !text-sm !font-normal !py-3.5 !px-4"}
                    variant={"contained"}
                    color={"primary"}
                    onClick={handleSubmit<TContactForm>((data) => {
                        if (!isLoading) {
                            setPromise(
                                notifierStore
                                    .contact({
                                        ...data,
                                        acTags: props.acTags,
                                        blogType: props.blogType,
                                        blogTitle: props.blogTitle,
                                        lang: i18next.language,
                                        url: pathname,
                                    })
                                    .then(() => {
                                        toast.success(t("contactPage.succeededSend"));
                                    }),
                            );
                        }
                    })}
                >
                    {isLoading ? t("loaders.message") : t("contactPage.form.getInTouch")}
                </ButtonNotification>
            </div>
            {/*<RecaptchaDisclaimer />*/}
        </>
    );
});
