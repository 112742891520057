import React from "react";
import { UnitViewSlider } from "units/UnitViewSlider";
import { GALLERY_TABS } from "_configs/propertyConfig";
import { observer } from "mobx-react";
import { TImage } from "_common/types/baseTypes";
import { galleryStore } from "gallery/_store/GalleryStore";
import { TUnitListingMdl } from "admin/units/_models/UnitListingMdl";
import { cn } from "_configs/twconfig";

type Props = {
    images: TImage[];
    unit?: TUnitListingMdl;
};
export const UnitGallery = observer(({ images, unit }: Props) => {
    return (
        <div className="flex flex-col lg:flex-row lg:justify-between mb-6 w-full gap-2">
            <UnitViewSlider
                images={images}
                onClick={() => galleryStore.setOpenedGalleryTab(GALLERY_TABS.GALLERY)}
                unit={unit}
            />
            {images.length > 1 && (
                <div className={cn("grid grid-cols-2 gap-2 md:h-[500px]", { "lg:grid-cols-1": images.length < 4 })}>
                    {images.slice(1, 5).map((image, index) => {
                        if (!image) return null;
                        return (
                            <div
                                key={index}
                                className={`relative aspect-video h-full w-full min-h-28`}
                                onClick={() => galleryStore.setOpenedGalleryTab(GALLERY_TABS.GALLERY)}
                            >
                                <img
                                    className="rounded-[15px] object-cover cursor-pointer w-full h-full"
                                    src={image.url}
                                    alt={`unit.photo`}
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = "https://placehold.co/300x200?text=Zisla.com";
                                    }}
                                />
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
});
