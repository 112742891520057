import React from "react";
import { Link } from "react-router-dom";
import { URLS } from "_configs/URLS";
import { NoSsr } from "@material-ui/core";
import { PropertyCardHeader } from "properties/propertyCard/components/PropertyCardHeader";
import { CardContainer, CardGalleryContainer } from "properties/propertyCard/components/PropertyCardComponents";
import { PropertyCardGallery } from "properties/propertyCard/components/PropertyCardGallery";
import { TPropertyCard } from "properties/_models/PropertyMdl";
import { PropertyCardInfos } from "properties/propertyCard/components/PropertyCardInfos";

type Props = {
    property: TPropertyCard;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    isLuxury?: boolean;
    onClick?: () => void;
    isUnitsFiltered?: boolean;
};

export function PropertyCardListing({
    property,
    onMouseEnter,
    onMouseLeave,
    isLuxury,
    onClick,
    isUnitsFiltered,
}: Props) {
    return (
        <CardContainer
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            className={"h-full shadow-[0px_0px_5px_0px_#1A3A454D] hover:shadow-[0px_0px_8px_0px_#1A3A454D]"}
        >
            <Link to={URLS.property(property.localized.urlAlias)}>
                {!!property.nbOfUnits && (
                    <NoSsr>
                        <PropertyCardHeader property={property} isUnitsFiltered={isUnitsFiltered} />
                    </NoSsr>
                )}
                <CardGalleryContainer>
                    <PropertyCardGallery property={property} isLuxury={isLuxury} />
                </CardGalleryContainer>
                <PropertyCardInfos property={property} />
            </Link>
        </CardContainer>
    );
}
