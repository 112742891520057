import { TFilter } from "admin/_common/filters/TFilter";
import { ListStore } from "_common/list/ListStore";
import { PROPERTY_TYPE, TPropertyListingMdl } from "properties/_models/PropertyMdl";
import sharedConfig from "_configs/sharedConfig";
import { capitalize, reformatStringForUrls } from "_common/_utils/alphaNumUtils";
import { getI18nExpByLang } from "_common/_utils/pageUtils";
import { PHASE_STATUS } from "admin/phases/_models/PhasesMdl";
import i18next from "i18next";
import { CURRENCY_OPTIONS } from "_common/currency/CurrencyTypes";
import { scientistNumber } from "_common/_utils/currencyUtils";
import dayjs from "dayjs";
import { toJS } from "mobx";
import { FILTER_TYPE, FILTERS_KEYS, INVENTORY_FILTERS_KEYS } from "_configs/filtersConfig";

export function getAllTypesInAllLang() {
    const allLang = Object.keys(sharedConfig.languages);
    const allTypes = Object.values(PROPERTY_TYPE);
    const allPropertiesInAllLang: string[] = [];
    allLang.map((lang) =>
        allTypes.map((type) =>
            allPropertiesInAllLang.push(reformatStringForUrls(getI18nExpByLang(lang, `property.typesUrl.${type}`))),
        ),
    );
    return allPropertiesInAllLang;
}

export function isPropertyType(search: string) {
    const allPropertiesInAllLang: string[] = getAllTypesInAllLang();
    return allPropertiesInAllLang.includes(search);
}

export function getFiltersValueKey(
    filters: TFilter[],
    filterKeys: (FILTERS_KEYS | INVENTORY_FILTERS_KEYS)[],
): TFilter[] {
    return filters
        .filter((filter) => filterKeys.some((filterKey) => filter.id.includes(filterKey)))
        .sort((a, b) => a.value - b.value);
}

export function getLabelFilterValue(filter: TFilter, currentCurrency: CURRENCY_OPTIONS) {
    const { id: filterKey, value, id2 } = filter;
    const unit = id2 ?? currentCurrency;
    if (filterKey === "type" || filterKey === "unitType") {
        if (!Array.isArray(toJS(value))) {
            const typeKey = filterKey === "type" ? `property.typeLabels.${value}` : `unit.type.label.${value}`;
            return capitalize(getI18nExpByLang(i18next.language, typeKey));
        }
        if (value.length === 1) {
            return capitalize(
                value?.[0] ? getI18nExpByLang(i18next.language, `property.typeLabels.${value?.[0]}`) : "",
            );
        }
        return `${value.length} ${getI18nExpByLang(i18next.language, "searchBar.labels.nSelected")}`;
    }
    if (filterKey.startsWith("bedroom")) {
        if (parseInt(value) === 0) return "Studio...";
        return `${value} ${getI18nExpByLang(i18next.language, "searchBar.filter.bedrooms")}...`;
    }
    if (filterKey.startsWith("bathroom")) {
        return `${value} ${getI18nExpByLang(i18next.language, "searchBar.filter.bathrooms")}...`;
    }
    if (filterKey.startsWith("amenities")) {
        return `${getI18nExpByLang(i18next.language, `property.amenities.${filterKey.split(".")[1]}`)}...`;
    }
    if (filterKey.startsWith("features")) {
        return `${getI18nExpByLang(i18next.language, `property.features.${filterKey.split(".")[1]}`)}...`;
    }
    if (filterKey.startsWith("squareSurface")) {
        return `${value} ${unit}...`;
    }
    if (filterKey.startsWith("bySquareSurface")) {
        return `${value} ${unit}...`;
    }
    if (filterKey.startsWith("phaseId")) {
        return `Phase (${value.length})`;
    }
    if (filterKey.startsWith(FILTERS_KEYS.STATUS)) {
        return `${getI18nExpByLang(i18next.language, `searchBar.filter.status.${value}`)}...`;
    }
    if (
        filterKey.startsWith(FILTERS_KEYS.DELIVERY_TYPE_START) ||
        filterKey.startsWith(FILTERS_KEYS.DELIVERY_TYPE_END)
    ) {
        return `${dayjs(value).format("MMM DD")}...`;
    }
    if (filterKey.startsWith("price.max") && unit) {
        const symbole = getI18nExpByLang(i18next.language, "searchBar.currencies." + unit);
        const convertedPrice = parseInt(value);
        return `${symbole}${scientistNumber(convertedPrice, 1)}..`;
    }
    if (filterKey.startsWith("price.min") && unit) {
        const symbole = getI18nExpByLang(i18next.language, "searchBar.currencies." + unit);
        const convertedPrice = parseInt(value);
        return `${symbole}${scientistNumber(convertedPrice, 1)}..`;
    }
    return value;
}

export const updateSearchFilter = (
    listStore: ListStore<TPropertyListingMdl>,
    filters: TFilter[],
    reload = true,
): Promise<ListStore<TPropertyListingMdl>> => {
    return new Promise((resolve) => {
        const locationIndex = filters.findIndex((filter) => filter.id === "location");
        const newFiltersIds = filters.map((filter) => filter.id);
        listStore.updateFilters([
            ...listStore.filters
                .filter((filter) => {
                    if (locationIndex > -1) {
                        return filter.id !== "location";
                    }
                    return true;
                })
                .filter((filter) => !newFiltersIds.includes(filter.id)),
            ...filters.filter(
                (filter) =>
                    ((filter.id.startsWith("amenities") || filter.id.startsWith("features")) && filter.value) ||
                    !(filter.id.startsWith("amenities") || filter.id.startsWith("features")),
            ),
        ]);
        if (reload) listStore.reload();
        resolve(listStore);
    });
};

export function getUrlSearchFromFilter(filterType = FILTER_TYPE.default, search: string, filter?: TFilter) {
    const searchParams = new URLSearchParams(search);
    switch (filterType) {
        case FILTER_TYPE.propertyType:
            if (filter) {
                if (searchParams.has(filter.id)) {
                    searchParams.delete(filter.id);
                }
                searchParams.set(filter.id, filter.value.join(","));
            }
            return searchParams.toString();
        case FILTER_TYPE.price:
            if (filter) {
                if (searchParams.has(filter.id)) {
                    searchParams.delete(filter.id);
                    searchParams.delete("currency");
                }
                searchParams.set(filter.id, filter.value.toString());
                if (filter.id2) {
                    searchParams.set("currency", filter.id2);
                }
            }
            return searchParams.toString();
        case FILTER_TYPE.bySquareSurface:
            if (filter) {
                if (searchParams.has(filter.id)) {
                    searchParams.delete(filter.id);
                    searchParams.delete("currency");
                    searchParams.delete("unit");
                }
                searchParams.set(filter.id, filter.value.toString());
                if (filter.id2) {
                    const currency = filter.id2.split("/")[1] as CURRENCY_OPTIONS;
                    const squareUnit = filter.id2.split("/")[0] as "m" | "ft";
                    searchParams.set("currency", currency);
                    searchParams.set("unit", squareUnit);
                }
            }
            return searchParams.toString();
        case FILTER_TYPE.squareSurface:
            if (filter) {
                if (searchParams.has(filter.id)) {
                    searchParams.delete(filter.id);
                    searchParams.delete("unit");
                }
                searchParams.set(filter.id, filter.value.toString());
                if (filter.id2) {
                    searchParams.set("unit", filter.id2);
                }
            }
            return searchParams.toString();
        case FILTER_TYPE.default:
            if (filter) {
                if (searchParams.has(filter.id)) {
                    searchParams.delete(filter.id);
                }
                if (filter.value === PHASE_STATUS.NONE) {
                    searchParams.delete("inventory.status");
                    searchParams.delete("inventory.startDate");
                    searchParams.delete("inventory.endDate");
                    return searchParams.toString();
                }
                searchParams.set(filter.id, filter.value.toString());
            }
            return searchParams.toString();
        default:
            return searchParams.toString();
    }
}

export function getUrlSearchFromFilters(filters: TFilter[]) {
    let search = "";
    filters.forEach((filter) => {
        switch (filter.id) {
            case FILTERS_KEYS.PURPOSE:
            case FILTERS_KEYS.PUBLISHED:
            case "status":
            case FILTERS_KEYS.LOCATION:
                break;
            case FILTERS_KEYS.TYPE:
                search = getUrlSearchFromFilter(FILTER_TYPE.propertyType, search, filter);
                break;
            case FILTERS_KEYS.PRICE_MAX:
            case FILTERS_KEYS.PRICE_MIN:
                search = getUrlSearchFromFilter(FILTER_TYPE.price, search, filter);
                break;
            case FILTERS_KEYS.BY_SQUARE_SURFACE_MAX:
            case FILTERS_KEYS.BY_SQUARE_SURFACE_MIN:
                search = getUrlSearchFromFilter(FILTER_TYPE.bySquareSurface, search, filter);
                break;
            case FILTERS_KEYS.SQUARE_SURFACE_MAX:
            case FILTERS_KEYS.SQUARE_SURFACE_MIN:
                search = getUrlSearchFromFilter(FILTER_TYPE.squareSurface, search, filter);
                break;
            default:
                search = getUrlSearchFromFilter(FILTER_TYPE.default, search, filter);
        }
    });
    return search;
}
