// SurfaceContext.tsx
import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { SURFACE_TYPE } from "_common/surfaces/SurfaceTypes";

type SurfaceContextType = {
    surfaceType: SURFACE_TYPE;
    setSurfaceType: (surface: SURFACE_TYPE) => void;
};

const SurfaceContext = createContext<SurfaceContextType | undefined>(undefined);

type SurfaceProviderProps = {
    children: ReactNode;
};

export const SurfaceProvider: React.FC<SurfaceProviderProps> = ({ children }: SurfaceProviderProps) => {
    const defaultValue: SURFACE_TYPE = __BROWSER__
        ? (localStorage.getItem("surface") as SURFACE_TYPE) || SURFACE_TYPE.m
        : SURFACE_TYPE.m;

    const [surfaceType, setSurfaceType] = useState<SURFACE_TYPE>(defaultValue);

    useEffect(() => {
        if (__BROWSER__) {
            localStorage.setItem("surface", surfaceType);
        }
    }, [surfaceType]);

    return <SurfaceContext.Provider value={{ surfaceType, setSurfaceType }}>{children}</SurfaceContext.Provider>;
};

export const useSurface = () => {
    const context = useContext(SurfaceContext);
    if (!context) {
        throw new Error("useSurface must be used within a SurfaceProvider");
    }
    return context;
};
