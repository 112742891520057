import { TFilter } from "admin/_common/filters/TFilter";
import { TFilterType } from "admin/_common/resources/ResourceFilterMdl";
import { PROPERTY_TYPE, PropertyTypesAndOptions } from "properties/_models/PropertyMdl";
import { reformatStringForUrls } from "_common/_utils/alphaNumUtils";
import i18next from "i18next";
import { getI18nExpByLang } from "_common/_utils/pageUtils";
import { FILTERS_KEYS } from "_configs/filtersConfig";

export function isPropertyType(supposedPropertyType: string) {
    const allTypes = Object.values(PROPERTY_TYPE);
    const allPropertiesInAllLang: string[] = [];
    allTypes.map((type) =>
        allPropertiesInAllLang.push(
            reformatStringForUrls(getI18nExpByLang(i18next.language, `property.typesUrl.${type}`)),
        ),
    );
    return allPropertiesInAllLang.includes(supposedPropertyType);
}

export function getFiltersFromQuery(query: { [key: string]: any }) {
    const filterFromQuery: TFilter[] = [];
    const filterType: string[] = [];
    Object.entries(query).forEach(([filterKey, filterValue]) => {
        if (filterKey.startsWith(FILTERS_KEYS.SQUARE_SURFACE_MAX)) {
            filterFromQuery.push({
                id: FILTERS_KEYS.SQUARE_SURFACE_MAX,
                type: TFilterType.NUMBER,
                value: parseFloat(filterValue as string),
                op: "gte",
                id2: query?.["unit"],
            });
        } else if (filterKey.startsWith(FILTERS_KEYS.SQUARE_SURFACE_MIN)) {
            filterFromQuery.push({
                id: FILTERS_KEYS.SQUARE_SURFACE_MIN,
                type: TFilterType.NUMBER,
                value: parseFloat(filterValue as string),
                op: "lte",
                id2: query?.["unit"],
            });
        } else if (filterKey.startsWith(FILTERS_KEYS.BY_SQUARE_SURFACE_MIN)) {
            filterFromQuery.push({
                id: FILTERS_KEYS.BY_SQUARE_SURFACE_MIN,
                type: TFilterType.NUMBER,
                value: parseFloat(filterValue as string),
                op: "lte",
                id2: `${query?.["unit"]}/${query?.["currency"]}`,
            });
        } else if (filterKey.startsWith(FILTERS_KEYS.BY_SQUARE_SURFACE_MAX)) {
            filterFromQuery.push({
                id: FILTERS_KEYS.BY_SQUARE_SURFACE_MAX,
                type: TFilterType.NUMBER,
                value: parseFloat(filterValue as string),
                op: "gte",
                id2: `${query?.["unit"]}/${query?.["currency"]}`,
            });
        } else if (filterKey.startsWith(FILTERS_KEYS.BATHROOMS_MAX)) {
            filterFromQuery.push({
                id: FILTERS_KEYS.BATHROOMS_MAX,
                type: TFilterType.NUMBER,
                value: parseFloat(filterValue as string),
                op: "gte",
            });
        } else if (filterKey.startsWith(FILTERS_KEYS.BATHROOMS_MIN)) {
            filterFromQuery.push({
                id: FILTERS_KEYS.BATHROOMS_MIN,
                type: TFilterType.NUMBER,
                value: parseFloat(filterValue as string),
                op: "lte",
            });
        } else if (filterKey.startsWith(FILTERS_KEYS.BEDROOMS_MAX)) {
            filterFromQuery.push({
                id: FILTERS_KEYS.BEDROOMS_MAX,
                type: TFilterType.NUMBER,
                value: parseFloat(filterValue as string),
                op: "gte",
            });
        } else if (filterKey.startsWith(FILTERS_KEYS.BEDROOMS_MIN)) {
            filterFromQuery.push({
                id: FILTERS_KEYS.BEDROOMS_MIN,
                type: TFilterType.NUMBER,
                value: parseFloat(filterValue as string),
                op: "lte",
            });
        } else if (filterKey.startsWith(FILTERS_KEYS.PRICE_MAX)) {
            filterFromQuery.push({
                id: FILTERS_KEYS.PRICE_MAX,
                type: TFilterType.NUMBER,
                value: parseFloat(filterValue as string),
                op: "gte",
                id2: query?.["currency"],
            });
        } else if (filterKey.startsWith(FILTERS_KEYS.PRICE_MIN)) {
            filterFromQuery.push({
                id: FILTERS_KEYS.PRICE_MIN,
                type: TFilterType.NUMBER,
                value: parseFloat(filterValue as string),
                op: "lte",
                id2: query?.["currency"],
            });
        } else if (filterKey.startsWith(FILTERS_KEYS.TYPE)) {
            (filterValue as string).split(",").map((filterValue) => filterType.push(filterValue));
        } else if (filterKey.startsWith("amenities.")) {
            const amenityKey = filterKey.slice("amenities.".length);
            const id = `amenities.${amenityKey}`;
            filterFromQuery.push({ id, value: filterValue, type: TFilterType.BOOLEAN });
        } else if (filterKey.startsWith("features.")) {
            const featureKey = filterKey.slice("features.".length);
            const id = `features.${featureKey}`;
            filterFromQuery.push({ id, value: filterValue, type: TFilterType.BOOLEAN });
        } else if (filterKey.startsWith("views.")) {
            const featureKey = filterKey.slice("views.".length);
            const id = `views.${featureKey}`;
            filterFromQuery.push({ id, value: filterValue, type: TFilterType.BOOLEAN });
        } else if (filterKey.startsWith(FILTERS_KEYS.DELIVERY_TYPE_END)) {
            filterFromQuery.push({
                id: FILTERS_KEYS.DELIVERY_TYPE_END,
                type: TFilterType.DATE,
                value: filterValue,
                op: "lte",
            });
        } else if (filterKey.startsWith(FILTERS_KEYS.STATUS)) {
            filterFromQuery.push({
                id: FILTERS_KEYS.STATUS,
                type: TFilterType.STRING,
                value: filterValue,
            });
        } else if (filterKey.startsWith(FILTERS_KEYS.DELIVERY_TYPE_START)) {
            filterFromQuery.push({
                id: FILTERS_KEYS.DELIVERY_TYPE_START,
                type: TFilterType.DATE,
                value: filterValue,
                op: "gte",
            });
        } else if (filterKey.startsWith(FILTERS_KEYS.UNIT_TYPE)) {
            filterFromQuery.push({
                id: FILTERS_KEYS.UNIT_TYPE,
                type: TFilterType.ENUM,
                value: filterValue,
            });
        }
    });

    return { filterFromQuery, filterType };
}

export function getTypeOrBeachfrontOrLuxuryFromFilters(filters: TFilter[]): PropertyTypesAndOptions | undefined {
    const filterBeachFrontOrLuxuryOrType = filters.find((filter) =>
        ["features.beachfront", "features.luxury", "type"].includes(filter.id),
    );
    if (!filterBeachFrontOrLuxuryOrType) return undefined;
    if (filterBeachFrontOrLuxuryOrType.id === "type") return filterBeachFrontOrLuxuryOrType.value[0];
    return filterBeachFrontOrLuxuryOrType.id.split(".")[1] as "beachfront" | "luxury";
}
