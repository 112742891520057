import React from "react";
import { TUnitListingMdl } from "admin/units/_models/UnitListingMdl";
import { UNIT_CONVERSION } from "_common/_utils/unityUtils";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { PROPERTY_TYPE } from "properties/_models/PropertyMdl";
import { rounded } from "_common/_utils/alphaNumUtils";
import { useSurface } from "_common/surfaces/SurfaceProvider";
import { SURFACE_TYPE } from "_common/surfaces/SurfaceTypes";

type Props = {
    unit: TUnitListingMdl;
};

export function InventorySurfaceCell({ unit }: Props) {
    const { surfaceType } = useSurface();
    const propertyStore = usePropertyStore();
    const squareMultiplyInFt2 = (value: number) => value && (value * UNIT_CONVERSION.M2_TO_FT2_BACK).toFixed(2);
    const surfaceConcerned =
        propertyStore.property.type === PROPERTY_TYPE.condo ? unit.totalConstructArea : unit.lotSurface;
    const value = surfaceType === SURFACE_TYPE.m ? surfaceConcerned : Number(squareMultiplyInFt2(surfaceConcerned));
    if (!value || isNaN(value)) return <div>-</div>;
    return (
        <div className={"text-xs sm:text-sm"}>{`${rounded(value, 2)}${
            surfaceType === SURFACE_TYPE.m ? "m²" : "ft²"
        }`}</div>
    );
}
