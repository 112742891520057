import { useTranslation } from "react-i18next";

export function useBedroomsNumberCardLabel(min: number, max?: number, bath?: boolean) {
    const { t } = useTranslation();
    const hasManyKindOfBedrooms = !!max && max != min;
    const BedOrBathKey = bath ? "bathrooms" : "bedrooms";
    if (hasManyKindOfBedrooms) {
        return t(`property.card.${BedOrBathKey}.rangeOfRooms`, {
            min: min,
            count: max,
            context: `${min}`,
        });
    }
    return t(`property.card.${BedOrBathKey}.numberOfRoom`, {
        count: min,
        context: `${min}`,
    });
}

export function useBedroomsNumberLabel(min: number, max?: number, bath?: boolean) {
    const { t } = useTranslation();
    const hasManyKindOfBedrooms = !!max && max != min;
    const BedOrBathKey = bath ? "bathrooms" : "bedrooms";
    if (hasManyKindOfBedrooms) {
        return t(`property.details.${BedOrBathKey}.rangeOfRooms`, {
            min: min,
            count: max,
            context: `${min}`,
        });
    }
    return t(`property.details.${BedOrBathKey}.numberOfRoom`, {
        count: min,
        context: `${min}`,
    });
}
