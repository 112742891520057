import i18next from "i18next";
import { TUnitTypeBackMdl, TUnitTypeMdl } from "units/_models/UnitTypeMdl";
import { FEES, TFees } from "properties/_models/PropertyMdl";
import { TUnitMdl } from "admin/units/_models/UnitMdl";

export function getTitleOfUnit(
    unit:
        | Pick<TUnitTypeBackMdl, "bedrooms" | "bathrooms" | "variant" | "type">
        | Pick<TUnitTypeMdl, "bedrooms" | "bathrooms" | "variant" | "type">,
) {
    if (!unit?.bedrooms || unit.bedrooms === 0) {
        return i18next.t(`unit.type.${unit.type}Studio`) + (unit?.variant ? ` - ${unit.variant}` : "");
    }
    return i18next.t(`unit.type.${unit.type}`, { count: unit.bedrooms }) + (unit?.variant ? ` - ${unit.variant}` : "");
}

export function isInPesosUnit(unit: { priceInPesos?: number }) {
    if (!unit || !unit?.priceInPesos) return false;
    return true;
}

export function getFees(propertyFees: TFees, unit: TUnitMdl) {
    let feesValue = 0;
    if (propertyFees.type === FEES.noFees) return feesValue.toFixed(2);
    if (propertyFees?.applyOnUnits) {
        feesValue = propertyFees?.surfaces
            ? propertyFees.surfaces.reduce((acc, surface) => {
                  return (unit?.[surface] ?? 0) + acc;
              }, 0) * propertyFees?.value ?? 0
            : 0;
    } else {
        feesValue = propertyFees.surfaces
            ? propertyFees.surfaces.reduce((acc, surface) => {
                  return (unit?.[surface] ?? 0) + acc;
              }, 0) * (unit?.fees?.value ?? 0) ?? propertyFees?.value
                ? propertyFees?.value
                : 0
            : 0;
    }
    return feesValue.toFixed(2);
}
