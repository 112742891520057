import React, { ComponentPropsWithoutRef, PropsWithChildren } from "react";
import styles from "../_css/propertyCardComponents.module.css";
import { cn } from "_configs/twconfig";

type CardProps = ComponentPropsWithoutRef<"div">;

export type CardPropsContainer = CardProps & {
    isHover?: boolean;
};
const CardContainer = ({ className, children, ...props }: PropsWithChildren<CardPropsContainer>) => {
    return (
        <div {...props} className={cn(styles.cardContainer, "p-3", className)}>
            {children}
        </div>
    );
};
CardContainer.displayName = "CardContainer";

const CardGalleryContainer = ({ className, ...props }: PropsWithChildren<CardProps>) => {
    return (
        <div {...props} className={cn(styles.cardGalleryContainer, "my-2", className)}>
            {props.children}
        </div>
    );
};
CardGalleryContainer.displayName = "CardGalleryContainer";

type CardGalleryLabelTypeProps = CardProps & {
    type: string;
};
const CardGalleryLabelType = ({ className, ...props }: CardGalleryLabelTypeProps) => {
    return (
        <div {...props} className={cn(styles.CardGalleryLabelType, "flex items-center justify-center", className)}>
            {props.type}
        </div>
    );
};
CardGalleryLabelType.displayName = "CardGalleryLabelType";

type CardGalleryLabelNew = CardProps & {
    text: string;
};

const CardGalleryTag = ({ className, ...props }: CardGalleryLabelNew) => {
    return (
        <div {...props} className={cn(styles.CardGalleryLabelNew, "flex items-center justify-center", className)}>
            {props.text}
        </div>
    );
};
CardGalleryTag.displayName = "CardGalleryTag";

const CardTitle = ({ className, ...props }: CardProps) => {
    return (
        <div {...props} className={cn(styles.CardTitle, className)}>
            {props.children}
        </div>
    );
};
CardTitle.displayName = "CardTitle";
const CardContent = ({ className, ...props }: CardProps) => {
    return (
        <div {...props} className={cn("flex flex-row items-center  text-xs", className)}>
            {props.children}
        </div>
    );
};
CardContent.displayName = "CardContent";

export { CardContainer, CardGalleryContainer, CardGalleryLabelType, CardGalleryTag, CardTitle, CardContent };
