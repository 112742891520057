import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { HiddenFieldsAttributerForm } from "components/form/HiddenFieldsAttributerForm";
import { useTranslation } from "react-i18next";
import { userStore } from "users/_stores/userStore";
import { ProjectFormBtn } from "properties/propertyPage/ProjectFormBtn";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { TContactForm } from "contact/ContactUsForm";
import { PropertyContactFields } from "properties/propertyPage/PropertyContactFields";
import { TOptionsForm } from "gallery/_store/GalleryStore";

type Props = {
    options: TOptionsForm;
};

function PropertyOrUnitContactForm({ options }: Props) {
    const { t } = useTranslation();
    const { property } = usePropertyStore();

    const form = useForm<TContactForm>({
        defaultValues: {
            name: userStore.isLogged ? userStore.user?.firstName + " " + userStore.user?.lastName : "",
            phone: userStore.isLogged ? userStore.user?.phone : "",
            email: userStore.isLogged ? userStore.user?.email : "",
            text: "",
            inquiry: "",
            workingWithAnAgent: "",
            newsletter: false,
        },
        mode: "onBlur",
    });

    return (
        <FormProvider {...form}>
            <div
                className={`sticky top-[130px] p-5 flex-3 shadow-[0px_0px_10px_0px_#1A3A4540] rounded-md lg:max-w-[500px] h-fit md:mt-0`}
            >
                <div className={"flex flex-col gap-3.5 mb-4"}>
                    <div className={"md:text-xl truncate"}>
                        {options?.title ??
                            t("propertyPage.contactForm.title", {
                                property: property.localized.title,
                            })}
                    </div>
                    <div className={"hidden sm:flex text-xs"}>{t("propertyPage.contactForm.subtitle")}</div>
                </div>
                <PropertyContactFields />
                <div className={"flex-1 flex flex-col"}>
                    <ProjectFormBtn options={options} />
                </div>
                <HiddenFieldsAttributerForm />
            </div>
        </FormProvider>
    );
}

export default PropertyOrUnitContactForm;
