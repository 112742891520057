import React from "react";
import { cn } from "_configs/twconfig";
import { LazyLoadImage } from "react-lazy-load-image-component";

type Props = {
    url?: string;
    type: "Image" | "Video" | "3DTour" | "Showroom";
    onClick: () => void;
    className?: string;
};

export function PropertyViewSmallImg({ url, type, onClick, className }: Props) {
    return (
        <div className={cn(`relative flex-1`, className)} onClick={onClick}>
            {type === "Video" || type === "3DTour" ? (
                <>
                    <div className="absolute rounded-[15px] h-full w-full cursor-pointer bg-[linear-gradient(180deg,rgba(255,255,255,0)_78%,rgba(0,0,0,0.7)_100%)]"></div>
                    <iframe
                        className={"rounded-[15px] cursor-pointer"}
                        width="100%"
                        height="100%"
                        src={url}
                        onClick={(e) => e.stopPropagation()}
                        allowFullScreen
                        title="YouTube Video"
                    />
                </>
            ) : (
                <LazyLoadImage
                    className={"rounded-[15px] h-full w-full object-cover cursor-pointer"}
                    src={url}
                    alt={`proprety_${type}_picture`}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = "https://placehold.co/300x200?text=Zisla.com";
                    }}
                />
            )}
            {type !== "Image" && (
                <div
                    className={
                        "grid items-center absolute left-[7px] opacity-70 bottom-[8px] min-w-20 h-5 md:h-6 rounded-[5px] bg-[#1A3E4D] px-2"
                    }
                >
                    <p className={"text-white text-center text-xs"}>{type}</p>
                </div>
            )}
        </div>
    );
}
