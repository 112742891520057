import { BaseResourceStore } from "_common/resources/BaseResourceStore";
import { TAddressMdl, TLocation, TPropertyMdl } from "properties/_models/PropertyMdl";
import { s6 } from "_common/_utils/coreUtils";
import sharedConfig, { TLang } from "_configs/sharedConfig";
import { EMPLOYEE_RIGHTS, IAddressMdl } from "users/_models/UserMdl";
import axios from "axios";
import { googleUri } from "_configs/googleConfig";
import { fetchUtils, TFilesData } from "_common/_utils/fetchUtils";
import { createFilesData, storeFile } from "_common/_utils/fileUtils";
import { TImage } from "_common/types/baseTypes";
import dayjs from "dayjs";
import { TCommunitiesMdl } from "admin/communities/_models/CommunitiesMdl";

export class PropertiesAdminStore extends BaseResourceStore<TPropertyMdl> {
    constructor() {
        super("properties");
    }

    async create(item: Partial<TPropertyMdl>, files?: TFilesData) {
        if (!item) return;
        if (item.address && !item.location) {
            const location = await this.getLocationFromAddress(item.address);
            item = { ...item, location };
        }
        return super.create(item, files);
    }

    async patch(item: Partial<TPropertyMdl>, files?: TFilesData) {
        if (!item) return;
        return super.patch(item, files);
    }

    async duplicate(itemId: string, photosItemId?: string, withInventory = false) {
        let item = await this.getAsync(itemId);
        const itemForPhotos = photosItemId ? await this.getAsync(photosItemId) : item;
        if (!item || !itemForPhotos) throw { key: "ITEM_NOT_FOUND" };

        for (const lang in item.localized) {
            item.localized[lang as TLang].urlAlias = item.localized[lang as TLang].urlAlias + s6();
            item.localized[lang as TLang].title = item.localized[lang as TLang].title + " - copy";
        }
        const { photos, filesData } = await this.duplicatePhotos(itemForPhotos.photos);

        item = {
            ...item,
            photos,
            floorPlans: [],
            showroom: [],
            previousProjects: [],
            finishings: [],
            premiumPhotos: [],
            previousProjectsVideos: [],
            price: { min: item.price.min * 100, max: (item.price?.max ?? 0) * 100 },
            priceInPesos: { min: item.priceInPesos.min * 100, max: (item.priceInPesos?.max ?? 0) * 100 },
            stats: { printed: { count: 0, date: new Date() }, visited: { count: 0, date: new Date() } },
            firstDayOnSite: dayjs(),
        };
        delete item._id;
        const newProperty = await this.duplicateItem(item, filesData);
        if (withInventory && newProperty) {
            await this.duplicateInventory(itemId, newProperty._id);
        }
        if (!newProperty) return undefined;
        return new Promise((resolve) => resolve(newProperty));
    }

    async duplicateInventory(fromPropertyId: string, toPropertyId: string) {
        return fetchUtils.post(`${sharedConfig.apiUrl}/inventories/duplicate`, { fromPropertyId, toPropertyId });
    }
    async duplicatePhotos(item: TImage[]) {
        const photos = [];
        const photosForFileData = [];
        for (const photo of item) {
            const blobImg = await fetch(photo.url).then((image) => image.blob());
            const blobUrl = storeFile(blobImg);
            photosForFileData.push(blobUrl);
            photos.push({ url: blobUrl });
        }
        const filesData = await createFilesData(photosForFileData, `photos.*.url`, 1920);
        return { filesData, photos };
    }

    async getLocationFromAddress(address: IAddressMdl | TAddressMdl | string): Promise<TLocation | undefined> {
        const { data } = await axios.get(googleUri.locationFromAddress(address));
        const { results } = data;
        if (results[0]) {
            return {
                type: "Point",
                coordinates: [results[0].geometry.location.lng, results[0].geometry.location.lat],
            } as TLocation;
        }
    }

    saveRights(rights: {
        [propertyId: string]: {
            [employeeId: string]: EMPLOYEE_RIGHTS | undefined;
        };
    }) {
        return fetchUtils.post(this.apiPath + "/rights", {
            rights,
        });
    }

    updateItemsFromInventory(propertyId: string) {
        return fetchUtils.post(this.apiPath + "/updateItemsFromInventory/" + propertyId);
    }

    async getCommunitiesByGeoZoneAdress(geoZoneId: string | undefined) {
        return await fetchUtils.post<TCommunitiesMdl[]>(this.apiPath + "/getCommunitiesByGeoZoneAddress", {
            geoZoneId,
        });
    }

    protected reformatItem(item: TPropertyMdl) {
        const reformatedItem = super.reformatItem(item);
        return {
            ...reformatedItem,
            price: {
                min: reformatedItem.price.min / 100,
                max: (reformatedItem?.price?.max ?? 0) / 100,
            },
            priceInPesos: {
                min: reformatedItem.priceInPesos?.min / 100,
                max: (reformatedItem?.priceInPesos?.max ?? 0) / 100,
            },
        };
    }
}

const propertiesAdminStore = new PropertiesAdminStore();
export { propertiesAdminStore };
