import React from "react";
import { TPropertyCard } from "properties/_models/PropertyMdl";
import { useTranslation } from "react-i18next";
import arrow from "../../../assets/images/rightArrowTiny.svg";
import { cn } from "_configs/twconfig";

type Props = {
    property: Pick<TPropertyCard, "nbOfUnitsFiltered" | "nbOfUnitsAvailable" | "subType">;
    className?: string;
    isUnitsFiltered?: boolean;
};

export function PropertyCardHeader({ property, isUnitsFiltered, className }: Props) {
    const { t } = useTranslation();
    const isBuildableHouse = property.subType === "buildableHouse";
    const countOfUnits = isUnitsFiltered ? property.nbOfUnitsFiltered : property.nbOfUnitsAvailable;
    return (
        <div className={cn("flex flex-row justify-between", className)}>
            {isBuildableHouse ? (
                <div className={"text-sm"}>{t("property.pairingAvailable", { count: countOfUnits })}</div>
            ) : (
                <div className={"text-sm"}>
                    {isUnitsFiltered
                        ? t("property.unitsFiltered", { count: countOfUnits })
                        : t("property.unitsAvailable", { count: countOfUnits })}
                </div>
            )}
            <div className={"flex flex-row items-center text-sm"}>
                {t("words.viewAll")}
                <img src={arrow} alt={"arrow"} className={"ml_5"} />
            </div>
        </div>
    );
}
