import React, { useEffect, useRef } from "react";
import styles from "./_css/propertiesView.module.css";
import { usePropertiesStore } from "properties/PropertiesPageContext";
import { PropertiesViewList } from "properties/PropertiesViewList";
import { usePropertyPageContext } from "properties/PropertyPageContext";
import { scrollTop } from "_common/_utils/ScrollTop";
import { PropertiesViewButtonsMobile } from "properties/PropertiesViewButtonsMobile";
import { PROPERTY_VIEW_STYLE } from "properties/components/BREAKPOINTS_SECTION_CARDS";
import { PropertiesViewMap } from "properties/PropertiesViewMap";
import { observer } from "mobx-react-lite";
import { PROPERTY_PURPOSE } from "properties/_models/PropertyMdl";
import { cn } from "_configs/twconfig";

export const PropertiesView = observer(() => {
    const propertiesStore = usePropertiesStore();
    const { mapOrListSelected } = usePropertyPageContext();
    const resultsContainer = useRef<HTMLDivElement>(null);
    const listStore = propertiesStore.getListStore(PROPERTY_PURPOSE.BUY);

    useEffect(() => {
        setTimeout(() => {
            if (resultsContainer && resultsContainer?.current?.scrollTop) {
                scrollTop(resultsContainer, resultsContainer.current.scrollTop);
            }
        }, 250);
    }, [mapOrListSelected, listStore.selectedPage]);

    return (
        <div
            className={cn("flex flex-col md:flex-row flex-1 relative", {
                "mb-14": mapOrListSelected === PROPERTY_VIEW_STYLE.LIST,
            })}
            onMouseLeave={() => propertiesStore.setMapPropertySelected(undefined)}
        >
            <div
                ref={resultsContainer}
                className={cn("flex flex-col px-2", {
                    [styles.resultsMap]: mapOrListSelected === PROPERTY_VIEW_STYLE.MAP,
                    ["w-0 hidden md:w-1/2 md:flex"]: mapOrListSelected === PROPERTY_VIEW_STYLE.MAP,
                    ["w-full h-full overflow-hidden"]: mapOrListSelected === PROPERTY_VIEW_STYLE.LIST,
                })}
            >
                <PropertiesViewList />
            </div>
            <div
                className={cn("relative flex flex-row flex-1", {
                    ["z-1"]: mapOrListSelected === PROPERTY_VIEW_STYLE.MAP,
                    ["w-full md:w-[50%] absolute -z-[100] -left-[150%]"]:
                        mapOrListSelected === PROPERTY_VIEW_STYLE.LIST,
                })}
            >
                <PropertiesViewMap />
            </div>
            <PropertiesViewButtonsMobile />
        </div>
    );
});
