import React, { useState } from "react";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import GalleryPagination from "properties/propertyPage/propertyView/GalleryPagination";
import { PropertyViewSmallImg } from "properties/propertyPage/PropertyViewSmallImg";
import { GALLERY_TABS, PROPERTY_OPTION } from "_configs/propertyConfig";
import PropertyViewLuxurySlider from "properties/propertyPage/propertyLuxuryView/PropertyViewLuxurySlider";
import { Dialog } from "@material-ui/core";
import PropertyViewListGalleryModal from "properties/propertyPage/propertyView/PropertyViewGalleryListModal";
import { observer } from "mobx-react";
import { cn } from "_configs/twconfig";
import { getYouTubeVideoId } from "communities/components/CommunitiesDiscover";
import { galleryStore } from "gallery/_store/GalleryStore";

const NAME = "propertyViewImageSlider";

export const PropertyHeaderLuxury = observer(() => {
    const propertyStore = usePropertyStore();
    const property = propertyStore.property;
    const isLuxury = !!property.features?.[PROPERTY_OPTION.luxury];
    const [currentImage, setCurrentImage] = useState(0);
    const hasShowroomOrSecondImage =
        (!!property.showroom && property.showroom.length > 0 && !!property.showroom[0]?.url) ||
        property.photos.length > 2;
    const hasVideoOrThirdImage = !!property.video || property.photos.length > 2;
    const has3DFloorOrFourthImage = !!property.visit3D || property.photos.length > 3;
    return (
        <div className={"flex flex-col gap-2"}>
            <div
                id={NAME}
                className={cn("w-full relative", {
                    "md:w-[calc(100%-25%)]":
                        (hasShowroomOrSecondImage || hasVideoOrThirdImage || has3DFloorOrFourthImage) && !isLuxury,
                })}
            >
                <PropertyViewLuxurySlider
                    currentImage={currentImage}
                    setCurrentImage={setCurrentImage}
                    onClick={() => galleryStore.setOpenedGalleryTab(GALLERY_TABS.GALLERY)}
                />

                <div className="hidden md:block">
                    <GalleryPagination
                        currentImage={currentImage}
                        totalImages={property.photos.length}
                        prevClass={`swiper-modal-button-prev ${NAME}`}
                        nextClass={`swiper-modal-button-next ${NAME}`}
                        className="grid items-center absolute left-[7px] opacity-70 bottom-[8px] w-16 h-5 md:w-20 md:h-6 rounded-[5px] bg-[#1A3E4D] z-50 text-center hidden md:block"
                        noArrow
                    />
                </div>
            </div>
            {(hasShowroomOrSecondImage || hasVideoOrThirdImage || has3DFloorOrFourthImage) && (
                <div className={"flex justify-between rounded gap-2 max-h-[250px]"}>
                    {hasShowroomOrSecondImage && (
                        <PropertyViewSmallImg
                            url={property.showroom?.[0]?.url ?? property.photos[1].url}
                            type={property.showroom?.[0]?.url ? "Showroom" : "Image"}
                            onClick={() => {
                                galleryStore.setOpenedGalleryTab(
                                    property.showroom.length ? GALLERY_TABS.SHOWROOM : GALLERY_TABS.GALLERY,
                                );
                            }}
                        />
                    )}
                    {hasVideoOrThirdImage && (
                        <PropertyViewSmallImg
                            url={property.video ? getYouTubeVideoId(property.video) : property.photos[2].url}
                            type={property.video ? "Video" : "Image"}
                            onClick={() => {
                                galleryStore.setOpenedGalleryTab(
                                    property.video ? GALLERY_TABS.VIDEO : GALLERY_TABS.GALLERY,
                                );
                            }}
                        />
                    )}
                    {has3DFloorOrFourthImage && (
                        <PropertyViewSmallImg
                            url={property.visit3D ? property.visit3D : property.photos[3].url}
                            type={property.visit3D ? "3DTour" : "Image"}
                            onClick={() => {
                                galleryStore.setOpenedGalleryTab(
                                    property.visit3D ? GALLERY_TABS.TOUR_3D : GALLERY_TABS.GALLERY,
                                );
                            }}
                        />
                    )}
                </div>
            )}
            <Dialog
                open={galleryStore.openedGalleryListTab !== "NONE"}
                onClose={() => galleryStore.closeGalleryTab()}
                fullScreen
            >
                <PropertyViewListGalleryModal />
            </Dialog>
        </div>
    );
});
