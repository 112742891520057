import React from "react";
import { URLS } from "_configs/URLS";
import { reformatStringForUrls } from "_common/_utils/alphaNumUtils";
import { getParentGeoZoneByProperty, isLuxury } from "_common/_utils/propertyUtils";
import { useTranslation } from "react-i18next";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { Breadcrumb } from "breadcrumb/Breadcrumb";

export function PropertyBreadcrumb() {
    const { t } = useTranslation();
    const { property } = usePropertyStore();
    const parentGeoZoneUrl = getParentGeoZoneByProperty(property);

    const items = [
        {
            itemLabel: t("propertyPage.research"),
            url: URLS.buy(),
            forceReload: true,
        },
        {
            itemLabel: property.address.city,
            url: URLS.buy(
                undefined,
                reformatStringForUrls(property.address.provinceShort),
                reformatStringForUrls(property.address.city),
            ),
            forceReload: true,
        },
    ];
    if (property.address.neighbourhood) {
        items.push({
            itemLabel: property.address.neighbourhood,
            url: parentGeoZoneUrl,
            forceReload: true,
        });
    }
    return <Breadcrumb items={items} currentItem={{ forceReload: false }} noMarginBottom white={isLuxury(property)} />;
}
