import React from "react";
import { useTranslation } from "react-i18next";
import { isInPesosProperty } from "_common/_utils/propertyUtils";
import { formatPesosPrice, formatPrice } from "_common/_utils/currencyUtils";
import { PropertyCardPrice } from "properties/propertyCard/components/PropertyCardPrice";
import { LazyLoadImage } from "react-lazy-load-image-component";
import location from "../../assets/images/icons/primaryLocation.svg";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";

export function PropertyPriceAndAddress() {
    const { t } = useTranslation();
    const { property } = usePropertyStore();

    const sortAddress = [
        property.address.neighbourhood,
        property.address.city,
        property.address.province,
        t("propertyPage.country." + (property.address.country ?? "MEX")),
    ];
    const completeAddress = sortAddress
        .filter((addressPart) => addressPart !== undefined && addressPart !== "")
        .slice(0)
        .join(", ");
    const hasMoreOneUnit = property.nbOfUnits > 1;
    return (
        <div className={"flex flex-col gap-5"}>
            <div className={"font-semibold text-xl ml-2.5"}>
                {property.priceIsNotDisplayed ? (
                    <>{t("propertiesPage.card.priceOnRequest")}</>
                ) : (
                    <>
                        {hasMoreOneUnit && t("words.fromWithColon")}
                        {isInPesosProperty(property) ? (
                            <>{formatPesosPrice(property.priceInPesos?.min)}</>
                        ) : (
                            <>{formatPrice(property.price?.min, false, false)}</>
                        )}
                    </>
                )}
            </div>

            <div className={"ml-2.5"}>
                <PropertyCardPrice
                    isInPesosProperty={isInPesosProperty(property)}
                    isPriceNotDisplayed={property.priceIsNotDisplayed}
                    priceInPesosMin={property.priceInPesos?.min ?? 0}
                    priceMin={property.price?.min ?? 0}
                    isNotFrom={!hasMoreOneUnit}
                />
            </div>

            <div className={"flex items-center"}>
                <LazyLoadImage src={location} />
                {completeAddress}
            </div>
        </div>
    );
}
