import React from "react";
import { TUnitListingMdl } from "admin/units/_models/UnitListingMdl";
import { useTranslation } from "react-i18next";

type Props = {
    unit: TUnitListingMdl;
};

export function InventoryBathCell({ unit }: Props) {
    const { t } = useTranslation();
    const key =
        unit.powderRooms && unit.powderRooms > 0
            ? "propertyPage.inventory.bathPowder"
            : "propertyPage.inventory.bathNumber";
    const noResults = unit.bathrooms === 0 && unit?.powderRooms === 0;
    return <div>{noResults ? "-" : t(key, { bath: unit.bathrooms, powder: unit.powderRooms })}</div>;
}
