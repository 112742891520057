import React from "react";
import { IconFavorite } from "properties/propertyPage/icons/IconFavorite";
import { TPropertyCard, TPropertyListingMdl } from "properties/_models/PropertyMdl";
import styles from "../_css/propertyCardFavoriteBtn.module.css";
import clsx from "clsx";
import { useFavoriteToggle } from "users/profile/favorite/useFavoriteToggle";
import { MAIN_RESOURCE } from "_common/resources/BaseResourceStore";
import { TUnitListingMdl } from "admin/units/_models/UnitListingMdl";
import { TUnitTypeListingMdl } from "units/_models/UnitTypeMdl";
import { observer } from "mobx-react-lite";

type Props = {
    item: TPropertyCard | TPropertyListingMdl | TUnitListingMdl | TUnitTypeListingMdl;
    resource: MAIN_RESOURCE;
};

export const CardFavoriteButton = observer(({ item, resource }: Props) => {
    const { isFavorite, toggleFavorite } = useFavoriteToggle(resource, item);
    return (
        <div
            className={clsx(styles.container, "position_absolute flex items-center justify-center hover:scale-110")}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                toggleFavorite();
            }}
        >
            <IconFavorite color={"black"} size={"15"} isFavorite={isFavorite} />
        </div>
    );
});
