import { OPTION_TYPE, TOptionConfigDef } from "_configs/propertyConfig";

export enum UNIT_OPTION {
    lockOffUnit = "lockOffUnit",
    privateEntrance = "privateEntrance",
    privateGarden = "privateGarden",
    privateJacuzzi = "privateJacuzzi",
    privatePool = "privatePool",
    privateTerrace = "privateTerrace",
    swimUp = "swimUp",
    unitOn3Floors = "unitOn3Floors",
    unitOn4Floors = "unitOn4Floors",
    unitOnTwoFloors = "unitOnTwoFloors",
    washerDryerInSuite = "washerDryerInSuite",
    furnished = "furnished",
    maidQuarter = "maidQuarter",
    storage = "storage",
    oceanView = "oceanView",
    jungleView = "jungleView",
    lagoonView = "lagoonView",
    partialOceanView = "partialOceanView",
    golfCourseView = "golfCourseView",
    interiorView = "interiorView",
    exteriorView = "exteriorView",
    streetView = "streetView",
    mountainView = "mountainView",
    cityView = "cityView",
    northView = "northView",
    eastView = "eastView",
    westView = "westView",
    southView = "southView",
    northWestView = "northWestView",
    northEastView = "northEastView",
    southWestView = "southWestView",
    southEastView = "southEastView",
    gardenView = "gardenView",
    poolView = "poolView",
    pierView = "pierView",
    amenitiesView = "amenitiesView",
    lateralView = "lateralView",
    marinaView = "marinaView",
    lakeView = "lakeView",
    frontView = "frontView",
    backView = "backView",
}

export const UNIT_OPTIONS: TOptionConfigDef[] = [
    { key: UNIT_OPTION.lockOffUnit, required: false, type: "boolean", filter: true, optionType: OPTION_TYPE.UNIT },
    { key: UNIT_OPTION.privateEntrance, required: false, type: "boolean", optionType: OPTION_TYPE.UNIT },
    { key: UNIT_OPTION.privateGarden, required: false, type: "boolean", optionType: OPTION_TYPE.UNIT },
    { key: UNIT_OPTION.privateJacuzzi, required: false, type: "boolean", optionType: OPTION_TYPE.UNIT },
    {
        key: UNIT_OPTION.privatePool,
        required: false,
        type: "number",
        max: 10,
        filter: true,
        optionType: OPTION_TYPE.UNIT,
    },
    {
        key: UNIT_OPTION.privateTerrace,
        required: false,
        type: "number",
        max: 10,
        filter: true,
        optionType: OPTION_TYPE.UNIT,
    },
    { key: UNIT_OPTION.unitOn3Floors, required: false, type: "boolean", optionType: OPTION_TYPE.UNIT },
    { key: UNIT_OPTION.swimUp, required: false, type: "boolean", optionType: OPTION_TYPE.UNIT },
    { key: UNIT_OPTION.unitOn4Floors, required: false, type: "boolean", optionType: OPTION_TYPE.UNIT },
    { key: UNIT_OPTION.unitOnTwoFloors, required: false, type: "boolean", optionType: OPTION_TYPE.UNIT },
    {
        key: UNIT_OPTION.washerDryerInSuite,
        required: false,
        type: "boolean",
        filter: true,
        optionType: OPTION_TYPE.UNIT,
    },
    { key: UNIT_OPTION.maidQuarter, type: "boolean", optionType: OPTION_TYPE.UNIT },
    { key: UNIT_OPTION.storage, type: "boolean", optionType: OPTION_TYPE.UNIT },

    { key: UNIT_OPTION.furnished, required: false, type: "boolean", filter: true, optionType: OPTION_TYPE.UNIT },
    { key: UNIT_OPTION.oceanView, type: "boolean", optionType: OPTION_TYPE.VIEWS, filter: true },
    { key: UNIT_OPTION.jungleView, type: "boolean", optionType: OPTION_TYPE.VIEWS, filter: true },
    { key: UNIT_OPTION.lagoonView, type: "boolean", optionType: OPTION_TYPE.VIEWS, filter: true },
    { key: UNIT_OPTION.partialOceanView, type: "boolean", optionType: OPTION_TYPE.VIEWS, filter: true },
    { key: UNIT_OPTION.golfCourseView, type: "boolean", optionType: OPTION_TYPE.VIEWS, filter: true },
    { key: UNIT_OPTION.interiorView, type: "boolean", optionType: OPTION_TYPE.VIEWS },
    { key: UNIT_OPTION.exteriorView, type: "boolean", optionType: OPTION_TYPE.VIEWS },
    { key: UNIT_OPTION.streetView, type: "boolean", optionType: OPTION_TYPE.VIEWS },
    { key: UNIT_OPTION.mountainView, type: "boolean", optionType: OPTION_TYPE.VIEWS },
    { key: UNIT_OPTION.cityView, type: "boolean", optionType: OPTION_TYPE.VIEWS },
    { key: UNIT_OPTION.northView, type: "boolean", optionType: OPTION_TYPE.VIEWS },
    { key: UNIT_OPTION.eastView, type: "boolean", optionType: OPTION_TYPE.VIEWS },
    { key: UNIT_OPTION.westView, type: "boolean", optionType: OPTION_TYPE.VIEWS },
    { key: UNIT_OPTION.southView, type: "boolean", optionType: OPTION_TYPE.VIEWS },
    { key: UNIT_OPTION.northWestView, type: "boolean", optionType: OPTION_TYPE.VIEWS },
    { key: UNIT_OPTION.northEastView, type: "boolean", optionType: OPTION_TYPE.VIEWS },
    { key: UNIT_OPTION.southWestView, type: "boolean", optionType: OPTION_TYPE.VIEWS },
    { key: UNIT_OPTION.southEastView, type: "boolean", optionType: OPTION_TYPE.VIEWS },
    { key: UNIT_OPTION.gardenView, type: "boolean", optionType: OPTION_TYPE.VIEWS },
    { key: UNIT_OPTION.poolView, type: "boolean", optionType: OPTION_TYPE.VIEWS },
    { key: UNIT_OPTION.pierView, type: "boolean", optionType: OPTION_TYPE.VIEWS },
    { key: UNIT_OPTION.amenitiesView, type: "boolean", optionType: OPTION_TYPE.VIEWS },
    { key: UNIT_OPTION.lateralView, type: "boolean", optionType: OPTION_TYPE.VIEWS },
    { key: UNIT_OPTION.marinaView, type: "boolean", optionType: OPTION_TYPE.VIEWS },
    { key: UNIT_OPTION.lakeView, type: "boolean", optionType: OPTION_TYPE.VIEWS },
    { key: UNIT_OPTION.frontView, type: "boolean", optionType: OPTION_TYPE.VIEWS },
    { key: UNIT_OPTION.backView, type: "boolean", optionType: OPTION_TYPE.VIEWS },
];
