import React, { useEffect, useMemo } from "react";
import { UnitStore } from "units/_stores/UnitStore";
import { TUnitListingMdl } from "admin/units/_models/UnitListingMdl";
import { UnitContext } from "units/UnitContext";
import { UnitGallery } from "units/UnitGallery";
import { UnitHeader } from "units/UnitHeader";
import { UnitCheckList } from "units/UnitCheckList";
import { GALLERY_NAME_MODAL, GALLERY_TABS } from "_configs/propertyConfig";
import { useTranslation } from "react-i18next";
import { GalleryListModal } from "units/UnitViewGalleryListModal";
import ShareButton from "properties/propertyPage/propertyView/ShareButton";
import ViewFavoriteButton from "properties/propertyPage/propertyView/ViewFavoriteButton";
import { MAIN_RESOURCE } from "_common/resources/BaseResourceStore";
import close from "../assets/images/icons/closeModal.svg";
import { UNIT_OPTION } from "_configs/unitConfig";
import { galleryStore } from "gallery/_store/GalleryStore";
import PropertyOrUnitFloorPlans from "properties/propertyPage/PropertyOrUnitFloorPlans";
import { RESOURCE } from "admin/_common/photos/AdminPhotosStore";
import UnitDescription from "units/UnitDescription";

type Props = {
    unit: TUnitListingMdl;
    onClose: () => void;
};

export function UnitPage({ unit, onClose }: Props) {
    const unitStore = useMemo(() => new UnitStore(unit), [unit]);
    const { t } = useTranslation();

    console.log(unit);

    // Set the modal name to GALLERY_NAME_MODAL.UNIT when the component mounts
    // and reset it to GALLERY_NAME_MODAL.PROPERTY when the component unmounts
    useEffect(() => {
        galleryStore.setModalName(GALLERY_NAME_MODAL.UNIT);

        return () => {
            galleryStore.setModalName(GALLERY_NAME_MODAL.PROPERTY);
        };
    }, []);

    return (
        <UnitContext unitStore={unitStore}>
            <div className="my-5 mx-5">
                <div className="flex justify-between">
                    <div className="flex mr-8 sm:mr-0 mb-3 gap-x-2">
                        <ShareButton unitName={unit.name} />
                        <ViewFavoriteButton item={unit} resource={MAIN_RESOURCE.UNIT} />
                    </div>
                    <div className="absolute right-5 top-3 z-60 cursor-pointer">
                        <img src={close} className="h-4" alt="close-modal" onClick={onClose} />
                    </div>
                </div>
                <UnitGallery images={unit.photos ?? []} unit={unit} />
                <UnitHeader unit={unit} />
                {unit.features && (
                    <UnitCheckList
                        title={t("unit.featuresTitle")}
                        datas={Object.entries(unit.features).filter(
                            ([key]) => unit.features?.[key as keyof typeof UNIT_OPTION],
                        )}
                    />
                )}
                {unit.description && <UnitDescription description={unit.description} />}
                <PropertyOrUnitFloorPlans
                    floorPlans={unit.floorPlans}
                    resource={RESOURCE.UNIT}
                    onClick={() => galleryStore.setOpenedGalleryTab(GALLERY_TABS.LEVEL_PLAN)}
                    height={"1000px"}
                />
            </div>
            <GalleryListModal
                galleryImages={[
                    {
                        images: unit.photos,
                        tabName: GALLERY_TABS.GALLERY,
                        label: t("property.galleryNavbar.mainGallery"),
                    },
                    {
                        images: unit.floorPlans,
                        tabName: GALLERY_TABS.LEVEL_PLAN,
                        label: t("property.galleryNavbar.levelPlan"),
                    },
                ]}
                item={unit}
            />
        </UnitContext>
    );
}
