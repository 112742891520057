import React from "react";
import "react-lazy-load-image-component/src/effects/blur.css";
import "swiper/swiper-bundle.min.css";
import { useTranslation } from "react-i18next";

interface UnitDescriptionProps {
    description: string;
}

export default function UnitDescription({ description }: UnitDescriptionProps) {
    const { t } = useTranslation();

    return (
        <div>
            <h2 className={"text-2xl font-medium text-[var(--color-primary)]"}>{t("words.description")}</h2>
            <div className={"relative py-5 rounded mb-10"} dangerouslySetInnerHTML={{ __html: description }} />
        </div>
    );
}
