import React from "react";
import clsx from "clsx";
import styles from "./_css/propertiesViewButtonsMobile.module.css";
import { PropertiesViewButtonMapList } from "properties/PropertiesViewButtonMapList";
import { SavedSearchButton } from "properties/SavedSearchButton";

export function PropertiesViewButtonsMobile() {
    return (
        <div
            className={clsx(
                styles.container,
                "fixed w-full z-[800] bottom-5 md:hidden flex items-center justify-center gap-4 transform -translate-x-2.5",
            )}
        >
            <PropertiesViewButtonMapList />
            <SavedSearchButton />
        </div>
    );
}
