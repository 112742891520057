import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "react-lazy-load-image-component/src/effects/blur.css";
import "swiper/swiper-bundle.min.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
import { isImage } from "_common/_utils/fileUtils";
import { RESOURCE } from "admin/_common/photos/AdminPhotosStore";
import { TImage } from "_common/types/baseTypes";

type Props = {
    floorPlans: TImage[];
    resource: RESOURCE;
    onClick: (index: number) => void;
    height?: string;
};

export default function PropertyOrUnitFloorPlans({ floorPlans, resource, onClick, height }: Props) {
    const { t } = useTranslation();

    if (floorPlans.length === 0) return null;
    return (
        <div id="propertyFloorPlans">
            <h2 className={"text-2xl font-medium text-[var(--color-primary)]"}>
                {t(resource === RESOURCE.UNIT ? "propertyPage.floorPlanUnit" : "propertyPage.floorPlan")}
            </h2>
            <div className={"relative py-5 rounded"}>
                <div hidden={floorPlans.length > 1}>
                    {isImage(floorPlans[0].url ?? "") ? (
                        <LazyLoadImage
                            onClick={() => onClick(0)}
                            alt={`${floorPlans[0].title ?? floorPlans[0].alt}-level-plan`}
                            title={`${floorPlans[0].title ?? floorPlans[0].alt} photo level plan`}
                            effect={"blur"}
                            className={"cursor-pointer w-full relative object-contain object-center"}
                            src={floorPlans[0].url}
                        />
                    ) : (
                        <embed
                            src={floorPlans[0].url + "#toolbar=0&navpanes=0"}
                            width="100%"
                            height={height ?? "400px"}
                            onClick={() => onClick(0)}
                        />
                    )}
                </div>
                <div hidden={floorPlans.length < 2}>
                    <Swiper navigation slidesPerView={1} loop initialSlide={0} className={"h-[600px]"}>
                        {floorPlans.map((image, index) => {
                            return (
                                <SwiperSlide key={index} className={"flex flex-row justify-center !w-full"}>
                                    {isImage(image.url ?? "") ? (
                                        <LazyLoadImage
                                            onClick={() => onClick(index)}
                                            alt={`${floorPlans[0].title ?? floorPlans[0].alt}-level-plan-${index}`}
                                            title={`${
                                                floorPlans[0].title ?? floorPlans[0].alt
                                            } photo level plan ${index}`}
                                            effect={"blur"}
                                            className={"cursor-pointer w-full relative object-contain"}
                                            src={image.url}
                                        />
                                    ) : (
                                        <div className={"cursor-pointer w-full relative overflow-auto"}>
                                            <div
                                                onClick={() => onClick(index)}
                                                className={"absolute h-full left-0 right-[15px] top-0 object-contain"}
                                            />
                                            <object
                                                data={image.url + "#toolbar=0&navpanes=0"}
                                                width="100%"
                                                height={height ?? "400px"}
                                                onClick={() => onClick(index)}
                                            />
                                        </div>
                                    )}
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </div>
            </div>
        </div>
    );
}
