import React from "react";
import { TFees } from "properties/_models/PropertyMdl";
import { useTranslation } from "react-i18next";
import UnitOrPropertyHeaderHoa from "units/UnitHeader/UnitOrPropertyHeaderHoa";
import { formatPesosPrice, formatPrice } from "_common/_utils/currencyUtils";
import { PropertyCardPrice } from "properties/propertyCard/components/PropertyCardPrice";
import { galleryStore } from "gallery/_store/GalleryStore";
import { MAIN_RESOURCE } from "_common/resources/BaseResourceStore";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { TUnitListingMdl } from "admin/units/_models/UnitListingMdl";
import { getFees } from "_common/_utils/unitUtils";

type Props = {
    item: {
        _id: string;
        name: string;
        price: number;
        priceInPesos: number;
        isInPesosUnit: boolean;
        priceIsNotDisplayed: boolean;
        fees?: TFees;
        variant?: string;
    };
    unit: TUnitListingMdl;
};

export function UnitHeaderPrices({ item, unit }: Props) {
    const { t } = useTranslation();
    const { property } = usePropertyStore();
    const name = item.variant ? item.name + " - " + item.variant : item.name;
    return (
        <div className={"flex flex-col gap-2 flex-1"}>
            <h2 className="text-2xl font-medium text-[#1A3A45] mb-4">{t("unitPage.header.title", { name: name })}</h2>
            <div className={"font-semibold text-[#1A3A45] text-xl"}>
                {item.priceIsNotDisplayed ? (
                    <>{t("propertiesPage.card.priceOnRequest")}</>
                ) : (
                    <>
                        {item.isInPesosUnit ? (
                            <>{formatPesosPrice(item.priceInPesos)}</>
                        ) : (
                            <>{formatPrice(item.price, false, false)}</>
                        )}
                    </>
                )}
            </div>
            <PropertyCardPrice
                isInPesosProperty={item.isInPesosUnit}
                priceInPesosMin={item.priceInPesos}
                priceMin={item.price}
                isPriceNotDisplayed={item.priceIsNotDisplayed}
                isNotFrom
            />
            <div className="mt-5">
                {!!item.fees && !!item.fees.value && (
                    <UnitOrPropertyHeaderHoa
                        title={t("propertyPage.fees")}
                        description={t(`propertyPage.feesTypes.${item.fees.type}`, {
                            count: getFees(property.fees, unit),
                        })}
                    />
                )}
            </div>
            <button
                className="w-52 text-white bg-[#1A3A45] rounded-full py-2.5 hidden md:block mt-5"
                onClick={() =>
                    galleryStore.openContactForm({
                        resource: MAIN_RESOURCE.UNIT,
                        title: t("unitPage.form.title", {
                            unit: item.name,
                            property: property.localized.title,
                        }),
                        unitId: item._id,
                    })
                }
            >
                {t("property.galleryNavbar.contact")}
            </button>
        </div>
    );
}
