import React from "react";
import { TPhasesMdl } from "admin/phases/_models/PhasesMdl";
import { usePhaseLabel } from "properties/propertyPage/inventory/usePhaseLabel";

type Props = {
    phase: TPhasesMdl;
};

export function PhaseLabel({ phase }: Props) {
    const phaseLabel = usePhaseLabel(phase);

    return <>{phaseLabel}</>;
}
