import { TErrMdl } from "_common/errors/_models/ErrMdl";
import i18next from "i18next";

export function getErrorMessage(error?: TErrMdl | string) {
    return typeof error === "string"
        ? error
        : i18next.t([error?.key ?? "errors.global.unknownLight", "errors.global.unknownLight"]);
}

export const UNKNOWN_ERROR: TErrMdl = { key: "errors.global.unknownLight" };
