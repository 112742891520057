import React from "react";
import { Dialog } from "_common/ui/dialogs/Dialog";
import { observer } from "mobx-react-lite";
import { galleryStore } from "gallery/_store/GalleryStore";
import PropertyOrUnitContactForm from "properties/propertyPage/PropertyOrUnitContactForm";

export const ContactFormDialog = observer(() => (
    <Dialog open={galleryStore.contactFormIsOpen} onClose={() => galleryStore.closeContactForm()}>
        <PropertyOrUnitContactForm options={galleryStore.optionsForm} />
    </Dialog>
));
