import React, { lazy } from "react";
import { PropertiesMeta } from "properties/PropertiesMeta";
import Suspenser from "_common/loaders/Suspenser";
import styles from "./_css/propertiesPage.module.css";
import clsx from "clsx";
import { Separator } from "_common/ui/components/Separator";
import { PropertiesView } from "properties/PropertiesView";
import { PropertiesViewContext } from "properties/PropertyPageContext";

const LazyPropertiesSearchBar = lazy(() => import("properties/searchBar/PropertiesSearchBar"));

export const PropertiesPage = () => {
    return (
        <PropertiesViewContext>
            <PropertiesMeta />
            <div className={"md:px-7 px-2.5"}>
                <div
                    className={clsx(
                        styles.searchBar,
                        "sticky top-0 bg-[var(--color-firstLvl-bg)] z-[var(--zIndex-searchBar)] flex items-center mb-2 lg:m-0",
                    )}
                >
                    <Suspenser noSpinner fallback={<div className={"h-[height:var(--height-searchBar)]"}></div>}>
                        <LazyPropertiesSearchBar />
                    </Suspenser>
                </div>
                <Separator className={"lg:hidden mb-3"} />
                <PropertiesView />
            </div>
        </PropertiesViewContext>
    );
};
