import React, { lazy } from "react";
import { Meta } from "_common/_utils/Meta";
import { geoZonesStore } from "geoZones/_stores/geoZonesStore";
import clsx from "clsx";
import { usePropertiesPageMeta } from "properties/usePropertiesPageMeta";
import { usePropertiesStore } from "properties/PropertiesPageContext";
import { useSearchPageParams } from "_common/_utils/hookUtils";
import { URLS } from "_configs/URLS";
import { PropertiesListLoader } from "properties/PropertiesListLoader";
import { PropertiesBreadcrumb } from "properties/PropertiesBreadcrumb";
import { GEO_ZONE_TYPE } from "geoZones/_models/GeoZoneMdl";
import Suspenser from "_common/loaders/Suspenser";
import { usePropertyPageContext } from "properties/PropertyPageContext";
import { PropertiesViewPagination } from "properties/PropertiesViewPagination";
import { PropertiesTagsSection } from "properties/PropertiesTagsSection";
import { PropertiesTypesSection } from "properties/PropertiesTypesSection";
import { PropertiesFaq } from "properties/PropertiesFaq";
import { PROPERTY_VIEW_STYLE } from "properties/components/BREAKPOINTS_SECTION_CARDS";
import { NoResults } from "pages/NoResults";
import { RESOURCE } from "admin/_common/photos/AdminPhotosStore";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { getTypeOrBeachfrontOrLuxuryFromFilters } from "_common/_utils/filterUtils";
import { PropertiesViewListItems } from "properties/PropertiesViewListItems";
import { observer } from "mobx-react-lite";
import { PROPERTY_PURPOSE } from "properties/_models/PropertyMdl";
import { PropertiesSort } from "properties/PropertiesSort";
import { PropertiesCount } from "properties/PropertiesCount";

const LazyPropertiesChildGeoZones = lazy(() => import("properties/components/PropertiesChildGeoZones"));
const LazyPropertiesNearbyGeoZones = lazy(() => import("properties/components/PropertiesNearbyGeoZones"));
const LazyPropertiesGatedCommunities = lazy(() => import("properties/components/PropertiesGatedCommunities"));

export const PropertiesViewList = observer(() => {
    const propertiesStore = usePropertiesStore();
    const listStore = propertiesStore.getListStore(PROPERTY_PURPOSE.BUY);
    const { mapOrListSelected } = usePropertyPageContext();
    const { isAtLeastProvincePage, beachFrontOrLuxury } = useSearchPageParams();
    const { title, metaTitle, metaDescription, geoZoneName } = usePropertiesPageMeta(propertiesStore, listStore);
    const geoZone = geoZonesStore.geoZone;
    const noIndex = isAtLeastProvincePage && !geoZone;

    const filterBeachFrontOrLuxuryOrType = getTypeOrBeachfrontOrLuxuryFromFilters(listStore.filters);

    return (
        <>
            <Meta title={metaTitle} description={metaDescription} url={noIndex ? URLS.home() : undefined} />
            <div className={"md:hidden pb_10"}>
                <PropertiesTagsSection mobile geoZoneName={geoZoneName} />
            </div>
            {!noIndex && <h1 className={"text-xl"}>{title}</h1>}
            <div className="flex flex-col md:flex-row md:justify-between my-5">
                <PropertiesSort />
                <PropertiesCount />
            </div>
            {listStore.count === 0 && <NoResults type={RESOURCE.PROPERTIES} />}
            <div
                className={clsx("grid grid-cols-1 gap-4", {
                    ["md:grid-cols-2 lg:grid-cols-3"]: mapOrListSelected === PROPERTY_VIEW_STYLE.LIST,
                    ["xl:grid-cols-2"]: mapOrListSelected === PROPERTY_VIEW_STYLE.MAP,
                })}
            >
                <LoadableFromLoading
                    loading={listStore.currentLoadingState}
                    contentRender={<PropertiesListLoader />}
                    renderer={() => {
                        return <PropertiesViewListItems />;
                    }}
                />
            </div>
            <PropertiesViewPagination />
            {!!geoZonesStore?.geoZone && (
                <div className={"flex items-center justify-center py-4"}>
                    <PropertiesBreadcrumb />
                </div>
            )}
            <div className={"mt-20 space-y-14"}>
                {(!geoZone?.isCommunity || beachFrontOrLuxury) && (
                    <PropertiesTypesSection geoZoneName={geoZoneName} geoZoneId={geoZone?._id} />
                )}
                <div className={"hidden md:block"}>
                    <PropertiesTagsSection geoZoneName={geoZoneName} />
                </div>
                {!geoZone?.isCommunity && (
                    <PropertiesFaq geoZone={geoZone} purpose={PROPERTY_PURPOSE.BUY} geoZoneName={geoZoneName} />
                )}
                {!geoZone?.isCommunity && (
                    <Suspenser noText noSpinner>
                        <LazyPropertiesNearbyGeoZones typeOrOptions={filterBeachFrontOrLuxuryOrType} />
                    </Suspenser>
                )}
                {geoZonesStore.geoZone?.type !== GEO_ZONE_TYPE.NEIGHBORHOOD && !geoZone?.isCommunity && (
                    <Suspenser noText noSpinner>
                        <LazyPropertiesChildGeoZones typeOrOptions={filterBeachFrontOrLuxuryOrType} />
                    </Suspenser>
                )}
                {!geoZone?.isCommunity && (
                    <Suspenser noText noSpinner>
                        <LazyPropertiesGatedCommunities geoZoneId={geoZone?._id} geoZoneName={geoZoneName} />
                    </Suspenser>
                )}
            </div>
        </>
    );
});
