import React from "react";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { BuildingsList } from "properties/propertyPage/inventory/BuildingsList";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { ListStore } from "_common/list/ListStore";
import { TColumnInventory } from "properties/propertyPage/inventory/InventoryColumns";
import { cn } from "_configs/twconfig";
import { observer } from "mobx-react";

type Props = {
    listStore: ListStore<any>;
    columns: TColumnInventory[];
};

export const InventoryTable = observer(({ listStore, columns }: Props) => {
    const propertyStore = usePropertyStore();

    return (
        <TableContainer className={"w-full max-h-[500px]"}>
            <Table size={"small"} stickyHeader>
                <TableHead className={"border border-[var(--color-primary)]"}>
                    <TableRow>
                        {columns.map((column, idx) => {
                            if (column.type && !column.type.includes(propertyStore.property.type)) return null;
                            return (
                                <TableCell
                                    key={column.id}
                                    align={"center"}
                                    className={cn(
                                        "!bg-[#F5F5F5] !border-t !border-t-[var(--color-primary)] text-sm !px-2 !py-1",
                                        { "!z-20": idx === 0 },
                                    )}
                                >
                                    {column.header()}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <LoadableFromLoading
                        loading={listStore.currentLoadingState}
                        renderer={() => <BuildingsList listStore={listStore} columns={columns} />}
                    />
                </TableBody>
            </Table>
        </TableContainer>
    );
});
