import React, { useState } from "react";
import { TableCell, TableRow } from "@material-ui/core";
import { TUnitListingMdl } from "admin/units/_models/UnitListingMdl";
import { cn } from "_configs/twconfig";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import calendar from "../../../assets/images/icons/calendar.svg";
import { TColumnInventory } from "properties/propertyPage/inventory/InventoryColumns";
import arrowUp from "../../../assets/images/icons/arrowUpExpendPrimary.svg";
import { PhaseLabel } from "properties/propertyPage/inventory/PhaseLabel";
import { PROPERTY_TYPE } from "properties/_models/PropertyMdl";
import { useTranslation } from "react-i18next";

type Props = {
    units: TUnitListingMdl[];
    buildingId: string;
    columns: TColumnInventory[];
};

export function UnitList({ units, buildingId, columns }: Props) {
    const propertyStore = usePropertyStore();
    const { t } = useTranslation();
    const [isCollapsed, setIsCollapsed] = useState(false);

    const building = propertyStore.property.inventory?.buildings.find((building) => building._id === buildingId);
    const phase = propertyStore.property.inventory?.phases.find((phase) => phase._id === building?.phaseId);

    if (!phase) return null;

    return (
        <>
            <TableRow
                key={buildingId}
                className={"cursor-pointer bg-[color:var(--color-primary-background)] h-[30px]"}
                onClick={() => setIsCollapsed(!isCollapsed)}
            >
                <TableCell colSpan={columns.length} className={"!border-b-0 !border-t !border-[var(--color-primary)] "}>
                    <div
                        className={
                            "flex items-center justify-between text-[color:var(--color-primary)] text-sm whitespace-nowrap"
                        }
                    >
                        <div className={"flex items-center gap-1 text-xs"}>
                            <img src={calendar} alt={"calendar icon"} className={"mr-2"} />
                            {propertyStore.property.type === PROPERTY_TYPE.condo && (
                                <div>{t("propertyPage.inventory.building")}</div>
                            )}
                            <div>{building?.name}</div>
                            <div>-</div>
                            <PhaseLabel phase={phase} />
                        </div>
                        <img src={arrowUp} alt={"arrow up"} className={cn({ "rotate-180": isCollapsed })} />
                    </div>
                </TableCell>
            </TableRow>
            {units
                .sort((a, b) => (a.floor.positionInBuilding > b.floor.positionInBuilding ? 1 : -1))
                .map((unit, idx) => {
                    if (!unit) return null;
                    const nextUnit = units[idx + 1];
                    const isLastInGroup =
                        !nextUnit || unit.floor.positionInBuilding !== nextUnit.floor.positionInBuilding;
                    return (
                        <TableRow
                            key={unit?._id}
                            className={cn({
                                ["!hidden"]: isCollapsed,
                            })}
                        >
                            {columns.map((column, idx) => {
                                if (column.type && !column.type.includes(propertyStore.property.type)) {
                                    return null;
                                }
                                return (
                                    <TableCell
                                        key={column.id}
                                        align={"center"}
                                        size={"small"}
                                        className={cn("!px-2 !py-1", {
                                            "!border-b !border-[var(--color-primary-lighter)]": isLastInGroup,
                                            "sticky left-0 z-10 bg-white": idx === 0,
                                        })}
                                    >
                                        {column.renderer(unit)}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    );
                })}
        </>
    );
}
