import { TFilter } from "admin/_common/filters/TFilter";
import { observable } from "mobx";

class AppFiltersStore {
    @observable private _filters: TFilter[] = [];
    constructor() {}

    updateFilters(filters: TFilter[]) {
        this._filters = filters;
    }

    get filters() {
        return this._filters.filter(
            (filter) => filter.id !== "location" && filter.id !== "published" && filter.id !== "type",
        );
    }
}

export const appFiltersStore = new AppFiltersStore();
